import { AddIngredientModal } from "components/NutritionPage/AddIngredientModal/AddIngredientModal";
import { TableComponent } from "components/TableComponent/TableComponent";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { GModal } from "components/genericComponents/GModal/GModal";
import { GSection } from "components/genericComponents/GSection/GSection";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa6";
import { CreateOrUpdateMealType } from "slices/dietSlice";
import { Food } from "types/nutrition";

import "components/NutritionPage/Diets/DietMeal/DietMeal.scss";
import { Diet } from "types/diet";
import tinycolor from "tinycolor2";

type DietMealProps = {
  meal: CreateOrUpdateMealType;
  setMeal: (meal: CreateOrUpdateMealType, index: number) => void;
  index: number;
  deleteMeal: (index: number) => void;
  isEditing?: boolean;
  diet?: Diet;
  ings?: Food[];
  weigs?: number[];
  setIngs?: (ings: Food[], index: number) => void;
  setWeigs?: (weigs: number[], index: number) => void;
};

export const DietMeal = ({
  meal,
  setMeal,
  index,
  deleteMeal,
  isEditing = false,
  diet,
  ings,
  weigs,
  setIngs,
  setWeigs,
}: DietMealProps) => {
  const [ingredients, setIngredients] = useState<Food[]>(ings ?? []);
  const [weights, setWeights] = useState<number[]>(weigs ?? []);
  const [showModal, setShowModal] = useState(false);
  const [showWeightModal, setShowWeightModal] = useState(false);
  const [activeWeightIndex, setActiveWeightIndex] = useState<number>(0);
  const [showMeal, setShowMeal] = useState(isEditing);
  const [hasDone, setHasDone] = useState(false);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      width: 400,
    },
    {
      Header: "Peso (g)",
      accessor: "weight_in_g",
      Cell: (props: any) => {
        return (
          <div className="weight-input-div">
            <div>
              <GInput
                key={`weight-input-${props.row.index}`}
                type="number"
                className="weight-input"
                disabled
                value={weights[props.row.index] ?? 0}
              />
            </div>
            <GButton
              label="Editar"
              onClick={() => {
                setActiveWeightIndex(props.row.index);
                setShowWeightModal(true);
              }}
              disabled={!isEditing}
            />
          </div>
        );
      },
    },
    {
      Header: "Calorias",
      accessor: "calories",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const caloriesPerWeight = (ingredient.calories * (ingredient.weight_in_g ?? 0)) / 100;
          return `${caloriesPerWeight.toFixed(0)}kcal`;
        }
        return "0kcal";
      },
    },
    {
      Header: "Proteínas",
      accessor: "protein",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const proteinPerWeight = (ingredient.protein * (ingredient.weight_in_g ?? 0)) / 100;
          return `${proteinPerWeight.toFixed(0)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Hidratos",
      accessor: "carbs",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const carbsPerWeight = (ingredient.carbs * (ingredient.weight_in_g ?? 0)) / 100;
          return `${carbsPerWeight.toFixed(0)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Lipídos",
      accessor: "lipids",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const lipidsPerWeight = (ingredient.lipids * (ingredient.weight_in_g ?? 0)) / 100;
          return `${lipidsPerWeight.toFixed(0)}g`;
        }
        return "0g";
      },
    },
  ];

  useEffect(() => {
    if (!hasDone && meal && diet) {
      const tempIngredients: Food[] = [];
      const tempWeights: number[] = [];
      meal.meal_items.forEach((item: any, idx: number) => {
        const ingredient = diet.meals[index].meal_items[idx].item as Food;
        firstSetIngredient(ingredient, item.weight_in_g, tempIngredients, tempWeights);
      });
      setIngredients(tempIngredients);
      setWeights(tempWeights);
      if (setIngs && setWeigs) {
        setIngs(tempIngredients, index);
        setWeigs(tempWeights, index);
      }
      setHasDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meal]);

  const firstSetIngredient = (ingredient: Food, weight: number = 0, tempIngredients: Food[], tempWeights: number[]) => {
    tempIngredients.push({ ...ingredient, weight_in_g: weight });
    tempWeights.push(weight);
  };

  const setIngredient = (ingredient: Food, weight: number = 0) => {
    setIngredients([...ingredients, { ...ingredient, weight_in_g: weight }]);
    setWeights([...weights, weight]);
    if (setIngs && setWeigs) {
      setIngs([...ingredients, { ...ingredient, weight_in_g: weight }], index);
      setWeigs([...weights, weight], index);
    }
    const tempMeal = meal;
    tempMeal.meal_items.push({ item_type: "Food", item_id: ingredient.id, weight_in_g: 0 });
    setMeal({ ...meal, meal_items: tempMeal.meal_items }, index);
  };

  return (
    <GSection
      label={meal.title ? meal.title : `Refeição ${index + 1}`}
      isSpacedBetween
      renderButtons={[
        ...(isEditing
          ? [
              <GButton
                label="Adicionar Pratos"
                onClick={() => {
                  setShowModal(true);
                }}
              />,
            ]
          : []),
        ...(isEditing
          ? [
              <GButton
                variant="delete"
                onClick={() => {
                  deleteMeal(index);
                }}
                className="delete-button"
              >
                <FaTrash />
              </GButton>,
            ]
          : []),
        <GButton
          variant="secondary"
          onClick={() => {
            setShowMeal(!showMeal);
          }}
          className="show-button"
        >
          {showMeal ? <FaChevronDown /> : <FaChevronUp />}
        </GButton>,
      ]}
    >
      {showMeal && (
        <div>
          <div className="info-div">
            <div className="info-div-inputs">
              <div>
                <p className="name">Nome da refeição</p>
                <GInput
                  value={meal.title}
                  onChange={(e: any) => setMeal({ ...meal, title: e.target.value }, index)}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <p className="name">Descrição</p>
                <GInput
                  value={meal.description}
                  onChange={(e: any) => setMeal({ ...meal, description: e.target.value }, index)}
                  as="textarea"
                  disabled={!isEditing}
                />
              </div>
              <div className="totals-div">
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#FF9800").brighten()} 0%, #FF9800 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients.length ? ingredients.reduce((acc: number, ingredient: Food, idx: number) => acc + ingredient.calories * (weights[idx] / 100), 0) : 0}kcal
                  </p>
                  <p className="totals-div-info-container-name">Calorias</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#4CAF50").brighten()} 0%, #4CAF50 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients.length ? ingredients.reduce((acc: number, ingredient: Food, idx: number) => acc + ingredient.protein * (weights[idx] / 100), 0) : 0}g
                  </p>
                  <p className="totals-div-info-container-name">Proteína</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#9C27B0").brighten()} 0%, #9C27B0 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients.length ? ingredients.reduce((acc: number, ingredient: Food, idx: number) => acc + ingredient.carbs * (weights[idx] / 100), 0) : 0}g
                  </p>
                  <p className="totals-div-info-container-name">Hidratos</p>
                </div>
                <div
                  className="totals-div-info-container"
                  style={{
                    background: `linear-gradient(to bottom, ${tinycolor("#03A9F4").brighten()} 0%, #03A9F4 100%)`,
                  }}
                >
                  <p className="totals-div-info-container-value">
                    {ingredients.length ? ingredients.reduce((acc: number, ingredient: Food, idx: number) => acc + ingredient.lipids * (weights[idx] / 100), 0) : 0}g
                  </p>
                  <p className="totals-div-info-container-name">Lípidos</p>
                </div>
              </div>
            </div>
          </div>
          <TableComponent
            name="ingredients"
            columns={tableColumns}
            data={ingredients}
            addAction={() => {}}
            rowClickAction={(row: any) => {}}
            removeAction={(id: string) => {
              const newIngredients = ingredients.filter((ingredient: Food) => ingredient.id !== id);
              setIngredients(newIngredients);
              setWeights(
                weights.filter(
                  (weight: number, index: number) =>
                    index !== ingredients.findIndex((ingredient: Food) => ingredient.id === id)
                )
              );
              if (setIngs && setWeigs) {
                setIngs(newIngredients, index);
                setWeigs(weights.filter((weight: number, index: number) => index !== ingredients.findIndex((ingredient: Food) => ingredient.id === id)), index);
              }
            }}
            duplicateAction={(id: string) => {}}
            showRemoveButton={isEditing}
            textWhenNoItems="Não tem nenhum prato adicionado"
          />
        </div>
      )}

      <AddIngredientModal
        showModal={showModal}
        setShowModal={setShowModal}
        ingredients={ingredients}
        setIngredient={setIngredient}
      />

      <GModal showModalState={showWeightModal} setShowModalState={setShowWeightModal}>
        <div className="add-ingredient-modal">
          <h4>Adicionar Peso</h4>
          <GInput
            type="number"
            className="weight-input"
            value={weights[activeWeightIndex] ?? 0}
            onChange={(e: any) => {
              const newWeight = Number(e.target.value);
              // Create a new array with all previous weights, replacing the value at the current index
              const newWeights = [...weights];
              newWeights[activeWeightIndex] = newWeight;
              setWeights(newWeights);
              if (setWeigs) {
                setWeigs(newWeights, index);
              }
              const tempMeal = meal;
              tempMeal.meal_items[activeWeightIndex] = {
                ...tempMeal.meal_items[activeWeightIndex],
                weight_in_g: newWeights[activeWeightIndex],
              };
              setMeal({ ...meal, meal_items: tempMeal.meal_items }, index);
            }}
          />
          <GButton
            label="Submeter"
            onClick={() => {
              const newIngredients = [...ingredients];
              newIngredients[activeWeightIndex].weight_in_g = Number(weights[activeWeightIndex]);
              setIngredients(newIngredients);
              if (setIngs && setWeigs) {
                setIngs(newIngredients, index);
              }
              const tempMeal = meal;
              tempMeal.meal_items[activeWeightIndex] = {
                ...tempMeal.meal_items[activeWeightIndex],
                weight_in_g: weights[activeWeightIndex],
              };
              
              setMeal({ ...meal, meal_items: tempMeal.meal_items }, index);
              setShowWeightModal(false);
            }}
          />
        </div>
      </GModal>
    </GSection>
  );
};
