import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { GModal } from "components/genericComponents/GModal/GModal";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { PaymentPlanCard } from "components/DashboardCustomersPage/CustomerPayments/PaymentPlanCard/PaymentPlanCard";
import { ChangePaymentsPlan } from "components/DashboardCustomersPage/CustomerPayments/ChangePaymentsPlan/ChangePaymentsPlan";
import { TableComponent } from "components/TableComponent/TableComponent";
import { ChangeUserInsurance } from "components/DashboardCustomersPage/CustomerPayments/ChangeUserInsurance/ChangeUserInsurance";
import { InsuranceCard } from "components/DashboardCustomersPage/CustomerPayments/InsuranceCard/InsuranceCard";
import { Payment } from "types/payments";
import {
  deleteUserPayment,
  fetchUserPayments,
  selectConfirmationMessage,
  selectErrorMessage,
  selectUserPayments,
  setConfirmationMessage,
  setErrorMessage,
  updateUserPayment,
} from "slices/paymentsSlice";
import { fetchUser, selectUsers } from "slices/userSlice";
import { PaymentsPackage } from "types/paymentPackages";
import {
  selectUserPaymentsPackage,
  selectErrorMessage as selectErrorPackageMessage,
  selectConfirmationMessage as selectConfirmationPackageMessage,
  setErrorMessage as setErrorPackageMessage,
  setConfirmationMessage as setConfirmationPackageMessage,
} from "slices/paymentsPackagesSlice";
import {
  fetchUserInsurance,
  selectUserInsurance,
  selectErrorMessage as selectErrorInsuranceMessage,
  selectConfirmationMessage as selectConfirmationInsuranceMessage,
  setErrorMessage as setErrorInsuranceMessage,
  setConfirmationMessage as setConfirmationInsuranceMessage,
} from "slices/insuranceSlice";

import "components/DashboardCustomersPage/CustomerPayments/CustomerPayments.scss";

export const CustomerPayments = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];
  const user = users.find((user) => user.id === userId);
  const payments: Payment[] = useSelector(selectUserPayments);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const errorPackageMessage = useSelector(selectErrorPackageMessage);
  const confirmationPackageMessage = useSelector(
    selectConfirmationPackageMessage
  );
  const errorInsuranceMessage = useSelector(selectErrorInsuranceMessage);
  const confirmationInsuranceMessage = useSelector(
    selectConfirmationInsuranceMessage
  );
  const userInsurance = useSelector(selectUserInsurance);
  const userPackage: PaymentsPackage | undefined = useSelector(
    selectUserPaymentsPackage
  );
  const [showChoosePlan, setShowChoosePlan] = useState<boolean>(false);
  const [showChooseInsurance, setShowChooseInsurance] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tableEntry, setTableEntry] = useState<Payment | undefined>(undefined);
  const [insuranceFetched, setInsuranceFetched] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchUserInsurance(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(fetchUser(userId));
    dispatch(fetchUserPayments(userId));
  }, [userPackage, dispatch, userId]);

  useEffect(() => {
    if (!insuranceFetched) {
      dispatch(fetchUser(userId));
      setInsuranceFetched(true);
    }
  }, [user, dispatch, insuranceFetched, userId]);

  const tableColumns = [
    {
      Header: "Estado",
      accessor: "is_paid",
      Cell: (props: any) => {
        return (
          <>
            {props.cell.value ? (
              <span>Pago</span>
            ) : (
              <GButton
                label="Pagar"
                onClick={() => {
                  setTableEntry(props.row.original);
                  setShowModal(true);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Tipo de pagamento",
      accessor: "package_title",
    },
    {
      Header: "Preço (€)",
      accessor: "price",
    },
    {
      Header: "Validade",
      accessor: "start_date",
      Cell: (props: any) => {
        return (
          <span>{`${format(
            new Date(props.cell.value),
            "dd/MM/yyyy"
          )} - ${format(
            new Date(props.row?.original?.end_date),
            "dd/MM/yyyy"
          )}`}</span>
        );
      },
    },
  ];

  const handleCloseChoosePlan = () => setShowChoosePlan(false);

  const handleConfirmPayment = () => {
    dispatch(
      updateUserPayment({
        userId,
        paymentId: tableEntry!.id,
      })
    );
    setShowModal(false);
    dispatch(fetchUserPayments(userId));
  };

  const handleCloseChooseInsurance = () => setShowChooseInsurance(false);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "payments-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "payments-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  useEffect(() => {
    if (errorPackageMessage !== undefined) {
      toast.error(errorPackageMessage, {
        toastId: "payments-package-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorPackageMessage(undefined));
    }
  }, [dispatch, errorPackageMessage]);

  useEffect(() => {
    if (confirmationPackageMessage !== undefined) {
      toast.success(confirmationPackageMessage, {
        toastId: "payments-package-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationPackageMessage(undefined));
    }
  }, [dispatch, confirmationPackageMessage]);

  useEffect(() => {
    if (errorInsuranceMessage !== undefined) {
      toast.error(errorInsuranceMessage, {
        toastId: "payments-insurance-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorInsuranceMessage(undefined));
    }
  }, [dispatch, errorInsuranceMessage]);

  useEffect(() => {
    if (confirmationInsuranceMessage !== undefined) {
      toast.success(confirmationInsuranceMessage, {
        toastId: "payments-insurance-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationInsuranceMessage(undefined));
      dispatch(fetchUserPayments(userId));
    }
  }, [dispatch, confirmationInsuranceMessage, userId]);

  return (
    <>
      {showChoosePlan ? (
        <ChangePaymentsPlan
          userId={userId}
          handleCloseChoosePlan={handleCloseChoosePlan}
          currentPlanId={
            user!.payment_package ? user?.payment_package!.id : undefined
          }
        />
      ) : showChooseInsurance ? (
        <ChangeUserInsurance
          insuranceId={userInsurance?.id}
          userId={userId}
          handleCloseChooseInsurance={handleCloseChooseInsurance}
        />
      ) : (
        <>
          <div className="customer-payments-plans-and-insurance-div">
            <GSection
              className="customer-payments-plans"
              label="Seguro"
              isSpacedBetween
              renderButtons={[
                <GButton
                  key="change-insurance"
                  variant="primary"
                  label="Alterar"
                  onClick={() => setShowChooseInsurance(true)}
                />,
              ]}
            >
              <Row>
                <Col className="customer-payments-plan-card-col">
                  {userInsurance ? (
                    <InsuranceCard startDate={userInsurance?.start_date} />
                  ) : (
                    <h5>
                      Não tem o seguro ativo. Adicione o seguro clicando no
                      botão Alterar.
                    </h5>
                  )}
                </Col>
              </Row>
            </GSection>
            <GSection
              className="customer-payments-plans"
              label="Plano Atual"
              isSpacedBetween
              renderButtons={[
                <GButton
                  key="change-payment-plan"
                  variant="primary"
                  label="Alterar"
                  onClick={() => setShowChoosePlan(true)}
                />,
              ]}
            >
              <Row>
                <Col className="customer-payments-plan-card-col">
                  {user?.payment_package ? (
                    <PaymentPlanCard
                      user={user}
                      plan={user.payment_package}
                      endDate={user.payment_package_end_date!}
                    />
                  ) : (
                    <h5>
                      Não tem planos ativos. Adicione um plano clicando no botão
                      Alterar Plano.
                    </h5>
                  )}
                </Col>
              </Row>
            </GSection>
          </div>
          <GSection className="customer-payments" label="Pagamentos">
            <div className="payments-table-div">
              {payments?.length ? (
                <TableComponent
                  className="no-hover no-mp"
                  name="customer"
                  columns={tableColumns}
                  data={payments}
                  rowClickAction={() => {}}
                  removeAction={(id: string) => {
                    dispatch(
                      deleteUserPayment({
                        userId: user!.id,
                        paymentId: id,
                      })
                    );
                  }}
                  showRemoveButton
                />
              ) : (
                <p>O cliente não tem pagamentos realizados.</p>
              )}
            </div>
          </GSection>
          <GModal
            showModalState={showModal}
            setShowModalState={(val) => setShowModal(val)}
            buttons={[
              <GButton
                key="confirm"
                label="Confirmar"
                onClick={handleConfirmPayment}
              />,
              <GButton
                key="cancel"
                label="Cancelar"
                onClick={() => setShowModal(false)}
                variant="secondary"
              />,
            ]}
            description="Tem a certeza que quer confirmar o pagamento?"
          />
        </>
      )}
    </>
  );
};
