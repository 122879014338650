import { axiosRequest, errorMessage } from "requests/request";
import { Payment, UpdatePayment, DestroyPayment } from "types/payments";
import { FetchPaymentsType, FetchSaftType } from "slices/paymentsSlice";

export type PaymentsRequestResponseType = {
  succeeded: boolean;
  payments: Payment[];
  payment: Payment;
  userPayments: Payment[];
  paymentUrl: string;
  userPayment: Payment;
  errorMessage: string | undefined;
  saft: string | undefined;
};

export const fetchPaymentsRequest = async (
  params: FetchPaymentsType
): Promise<PaymentsRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `user_payments?from=${params.from}&to=${params.to}&search=${params.search}`
    );

    return {
      succeeded: true,
      payments: response.data,
    } as PaymentsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsRequestResponseType;
  }
};

export const fetchSaftRequest = async (
  params: FetchSaftType
): Promise<PaymentsRequestResponseType> => {
  try {
    const response = await axiosRequest().get(
      `vendus/saft?year=${params.year}&month=${params.month}`
    );

    return {
      succeeded: true,
      saft: response.data["xml"],
    } as PaymentsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsRequestResponseType;
  }
};

export const fetchUserPaymentsRequest = async (
  userId: string
): Promise<PaymentsRequestResponseType> => {
  try {
    const response = await axiosRequest().get(`users/${userId}/payments`);

    return {
      succeeded: true,
      userPayments: response.data,
    } as PaymentsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsRequestResponseType;
  }
};

export const updateUserPaymentRequest = async (
  payment: UpdatePayment
): Promise<PaymentsRequestResponseType> => {
  try {
    const response = await axiosRequest().put(
      `users/${payment.userId}/payments/${payment.paymentId}`,
      {
        payment: { is_paid: true },
      }
    );

    return {
      succeeded: true,
      userPayment: response.data,
    } as PaymentsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsRequestResponseType;
  }
};

export const destroyUserPaymentRequest = async (
  payment: DestroyPayment
): Promise<PaymentsRequestResponseType> => {
  try {
    const response = await axiosRequest().delete(
      `users/${payment.userId}/payments/${payment.paymentId}`
    );

    return {
      succeeded: true,
      userPayment: response.data,
    } as PaymentsRequestResponseType;
  } catch (e: any) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as PaymentsRequestResponseType;
  }
};

export const createPaymentToGoactiveRequest =
  async (): Promise<PaymentsRequestResponseType> => {
    try {
      const response = await axiosRequest().post("viva/goactive_payments");

      return {
        succeeded: true,
        paymentUrl: response.data.url,
      } as PaymentsRequestResponseType;
    } catch (e: any) {
      return {
        succeeded: false,
        errorMessage: errorMessage(e),
      } as PaymentsRequestResponseType;
    }
  };
