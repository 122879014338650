import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "components/TableComponent/TableComponent";
import {
  deleteRecipe,
  fetchRecipes,
  selectConfirmationMessage,
  selectErrorMessage,
  selectRecipes,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/nutritionSlice";
import { toast } from "react-toastify";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { Recipe } from "types/nutrition";
import { User } from "types/user";
import { selectCurrentUser } from "slices/currentUserSlice";
import { Form } from "react-bootstrap";
import { FilterDropdown } from "components/FilterDropdown/FilterDropdown";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
} from "slices/personalTrainerSlice";

type creatorSelect = {
  id: string;
  name: string;
  image_small: string;
  is_gym: boolean;
};

export const Recipes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isGymView = localStorage.getItem("isGymView") === "true";
  const recipes = useSelector(selectRecipes);
  const [recipesToShow, setRecipesToShow] = useState<Recipe[]>(recipes);
  const [personalTrainersAndCompany, setPersonalTrainersAndCompany] = useState<
    creatorSelect[]
  >([]);

  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const currentUser = useSelector(selectCurrentUser);
  const recipesFiltersLocalStorage = localStorage.getItem("recipeFilters");
  const personalTrainers = useSelector(selectPersonalTrainers);
  const [searchInput, setSearchInput] = useState<string | undefined>(
    recipesFiltersLocalStorage
      ? JSON.parse(recipesFiltersLocalStorage).searchInput
      : undefined
  );
  const [selectedCreator, setSelectedCreator] = useState<
    creatorSelect | undefined
  >(
    recipesFiltersLocalStorage
      ? JSON.parse(recipesFiltersLocalStorage).selectedCreator
      : undefined
  );

  useEffect(() => {
    dispatch(fetchRecipes());
    dispatch(fetchPersonalTrainers());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && personalTrainers) {
      const pts = personalTrainers.map((personal_trainer: User) => {
        return {
          id: personal_trainer.id!,
          name: formatToFirstTwoAndLastName(personal_trainer.name),
          image_small: personal_trainer.image_small,
          is_gym: false,
        };
      });

      const company = {
        id: currentUser.company!.id!,
        name: currentUser.company!.name,
        image_small: currentUser.company!.image_small,
        is_gym: true,
      };

      setPersonalTrainersAndCompany(pts.concat([company]));
    }
  }, [currentUser, personalTrainers]);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
    },
    {
      Header: "Peso",
      accessor: "totals.weight_in_g",
      Cell: (props: any) => {
        return `${props.row.original.totals.weight_in_g.toFixed(2)}g`;
      },
    },
    {
      Header: "Calorias",
      accessor: "totals.calories",
      Cell: (props: any) => {
        return `${props.row.original.totals.calories.toFixed(2)}kcal`;
      },
    },
    {
      Header: "Proteínas",
      accessor: "totals.protein",
      Cell: (props: any) => {
        return `${props.row.original.totals.protein.toFixed(2)}g`;
      },
    },
    {
      Header: "Hidratos",
      accessor: "totals.carbs",
      Cell: (props: any) => {
        return `${props.row.original.totals.carbs.toFixed(2)}g`;
      },
    },
    {
      Header: "Lipídos",
      accessor: "totals.lipids",
      Cell: (props: any) => {
        return `${props.row.original.totals.lipids.toFixed(2)}g`;
      },
    },
    {
      Header: "Criado por",
      accessor: "created_by",
      Cell: (props: any) => {
        let image;
        let name;

        if (props.row.original.created_by_gym) {
          image = currentUser?.company?.image_small;
          name = currentUser?.company?.name;
        } else {
          image = props.cell.value.image;
          name = formatToFirstTwoAndLastName(props.cell.value.name);
        }

        return (
          <>
            {props.cell.value !== null ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt=""
                  style={{ width: 30, height: 30, borderRadius: 15 }}
                  src={
                    image ??
                    "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                  }
                />
                <p style={{ margin: 0, marginLeft: 10 }}>{name}</p>
              </div>
            ) : undefined}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "delete-recipe-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "delete-recipe-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      dispatch(fetchRecipes());
    }
  }, [dispatch, confirmationMessage, navigate]);

  useEffect(() => {
    localStorage.setItem(
      "recipeFilters",
      JSON.stringify({
        searchInput,
        selectedCreator,
      })
    );
  }, [searchInput, selectedCreator]);

  useEffect(() => {
    var recipesFiltered = recipes;

    if (searchInput !== undefined) {
      recipesFiltered = recipesFiltered.filter((recipe: Recipe) =>
        recipe.name.toLowerCase().includes(searchInput.toLowerCase())
      );

      setRecipesToShow(recipesFiltered);
    }

    if (selectedCreator !== undefined) {
      recipesFiltered = recipesFiltered.filter((recipe: Recipe) => {
        if (selectedCreator.is_gym) {
          return recipe.created_by_gym;
        } else {
          return (
            recipe.created_by.id === selectedCreator.id &&
            !recipe.created_by_gym
          );
        }
      });

      setRecipesToShow(recipesFiltered);
    }

    if (searchInput === undefined && selectedCreator === undefined) {
      setRecipesToShow(recipes);
    }
  }, [searchInput, selectedCreator, recipes]);

  return (
    <div>
      <div className="filter-container">
        <Form.Control
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Pesquisar"
          className="search-input"
        />
        {isGymView && <FilterDropdown
          title={"Personal Trainer"}
          values={personalTrainersAndCompany}
          selectedValue={selectedCreator}
          onChangeValue={(creatorId: any) => {
            if (creatorId === undefined) return setSelectedCreator(undefined);

            if (creatorId === currentUser!.company!.id) {
              setSelectedCreator({
                id: currentUser!.company!.id,
                name: formatToFirstTwoAndLastName(currentUser!.company!.name),
                image_small: currentUser!.company!.image_small,
                is_gym: true,
              });
            } else {
              const newValue = personalTrainers.find(
                (personalTrainer) => personalTrainer.id === creatorId
              );

              setSelectedCreator({
                id: newValue!.id,
                name: formatToFirstTwoAndLastName(newValue!.name),
                image_small: newValue!.image_small,
                is_gym: false,
              });
            }
          }}
        />}
      </div>
      <TableComponent
        name="recipes"
        columns={tableColumns}
        data={recipesToShow}
        addAction={() => {}}
        rowClickAction={(row: any) => {
          navigate(`${row.original.id}`);
        }}
        removeAction={(id: string) => {
          dispatch(deleteRecipe(id));
        }}
        duplicateAction={(id: string) => {}}
        showRemoveButton
        textWhenNoItems="Não tem nenhuma receita criada"
      />
    </div>
  );
};
