import { useEffect, useState } from "react";

import { GInput } from "components/genericComponents/GInput/GInput";
import { TableComponent } from "components/TableComponent/TableComponent";
import { Food } from "types/nutrition";

import "components/NutritionPage/AddRecipe/AddRecipe.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GModal } from "components/genericComponents/GModal/GModal";
import { useDispatch, useSelector } from "react-redux";
import {
  createRecipe,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/nutritionSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddIngredientModal } from "../AddIngredientModal/AddIngredientModal";

export const AddRecipe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showWeightModal, setShowWeightModal] = useState<boolean>(false);
  const [ingredients, setIngredients] = useState<Food[]>([]);
  const [weights, setWeights] = useState<number[]>([]);
  const [activeWeightIndex, setActiveWeightIndex] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      width: 400,
    },
    {
      Header: "Peso (g)",
      accessor: "weight_in_g",
      Cell: (props: any) => {
        return (
          <div className="weight-input-div">
            <div>
              <GInput
                key={`weight-input-${props.row.index}`}
                type="number"
                className="weight-input"
                disabled
                value={weights[props.row.index] ?? 0}
              />
            </div>
            <GButton
              label="Editar"
              onClick={() => {
                setActiveWeightIndex(props.row.index);
                setShowWeightModal(true);
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Calorias",
      accessor: "calories",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const caloriesPerWeight =
            (ingredient.calories * (ingredient.weight_in_g ?? 0)) / 100;
          return `${caloriesPerWeight.toFixed(2)}kcal`;
        }
        return "0kcal";
      },
    },
    {
      Header: "Proteínas",
      accessor: "protein",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const proteinPerWeight =
            (ingredient.protein * (ingredient.weight_in_g ?? 0)) / 100;
          return `${proteinPerWeight.toFixed(2)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Hidratos",
      accessor: "carbs",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const carbsPerWeight =
            (ingredient.carbs * (ingredient.weight_in_g ?? 0)) / 100;
          return `${carbsPerWeight.toFixed(2)}g`;
        }
        return "0g";
      },
    },
    {
      Header: "Lipídos",
      accessor: "lipids",
      Cell: (props: any) => {
        const ingredient = ingredients[props.row.index];
        if (ingredient) {
          const lipidsPerWeight =
            (ingredient.lipids * (ingredient.weight_in_g ?? 0)) / 100;
          return `${lipidsPerWeight.toFixed(2)}g`;
        }
        return "0g";
      },
    },
  ];

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "add-recipe-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "add-recipe-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
      navigate("/dashboard/nutrition/recipes");
    }
  }, [dispatch, confirmationMessage, navigate]);

  const setIngredient = (ingredient: Food) => {
    setIngredients([...ingredients, { ...ingredient, weight_in_g: 0 }]);
    setWeights([...weights, 0]);
  };

  const submitRecipe = () => {
    const ingredientsWithWeights = ingredients.map((ingredient, index) => ({
      id: ingredient.id,
      weight_in_g: weights[index],
    }));
    dispatch(createRecipe({ name, foods: ingredientsWithWeights }));
  };

  return (
    <div className="add-recipe-container">
      <div className="add-recipe-header">
        <h2>Nova Receita</h2>
        <GButton
          label="Adicionar Ingrediente"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <div className="name-div">
        <div>
          <p className="name">Nome</p>
          <GInput value={name} onChange={(e: any) => setName(e.target.value)} />
        </div>
      </div>
      <TableComponent
        name="ingredients"
        columns={tableColumns}
        data={ingredients}
        addAction={() => {}}
        rowClickAction={(row: any) => {}}
        removeAction={(id: string) => {
          setIngredients(
            ingredients.filter((ingredient: Food) => ingredient.id !== id)
          );
          setWeights(
            weights.filter(
              (weight: number, index: number) =>
                index !==
                ingredients.findIndex(
                  (ingredient: Food) => ingredient.id === id
                )
            )
          );
        }}
        duplicateAction={(id: string) => {}}
        showRemoveButton
        textWhenNoItems="Não tem nenhum ingrediente adicionado"
      />
      {ingredients.length > 0 && name && (
        <div className="submit-button-div">
          <GButton label="Submeter" onClick={submitRecipe} />
        </div>
      )}

      <AddIngredientModal
        showModal={showModal}
        setShowModal={setShowModal}
        ingredients={ingredients}
        setIngredient={setIngredient}
      />

      <GModal
        showModalState={showWeightModal}
        setShowModalState={setShowWeightModal}
      >
        <div className="add-ingredient-modal">
          <h4>Adicionar Peso</h4>
          <GInput
            type="number"
            className="weight-input"
            value={weights[activeWeightIndex] ?? 0}
            onChange={(e: any) => {
              const newWeight = Number(e.target.value);
              // Create a new array with all previous weights, replacing the value at the current index
              const newWeights = [...weights];
              newWeights[activeWeightIndex] = newWeight;
              setWeights(newWeights);
            }}
          />
          <GButton
            label="Submeter"
            onClick={() => {
              const newIngredients = [...ingredients];
              newIngredients[activeWeightIndex].weight_in_g = Number(
                weights[activeWeightIndex]
              );
              setIngredients(newIngredients);
              setShowWeightModal(false);
            }}
          />
        </div>
      </GModal>
    </div>
  );
};
