import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User, UserCounter, UserWithMissingPayments } from "../types/user";
import {
  userSuccessfullyCreated,
  userSuccessfullyUpdated,
  userSuccessfullyDeleted,
} from "../helpers/messages";

interface UserState {
  currentUser: User | undefined;
  currentClient: User | undefined;
  users: Array<User>;
  isLoading: boolean;
  errorMessage: string | undefined;
  confirmationMessage: string | undefined;
  userCounters: Array<UserCounter>;
  usersWithMissingPayments: Array<UserWithMissingPayments>;
  revenue: number;
}

export type FetchUserRevenueType = {
  start_at: string;
  end_at: string;
};

export type UserImageType = {
  id: string;
  storage: string;
  metadata: {
    size: number;
    mime_type: string;
  };
};

export type CreateUserType = {
  name: string | null;
  email: string | null;
  locale: string | null;
  phone_number: number | null;
  birthday_date: string | null;
  imc: number | null;
  fat_mass_percentage: number | null;
  blood_pressure_sistolic: number | null;
  blood_pressure_diastolic: number | null;
  resting_heart_beats: number | null;
  has_surgical_interventions: boolean;
  surgical_interventions: string | null;
  has_spine_issues: boolean;
  spine_issues: string | null;
  is_smoker: boolean;
  smoker_info: string | null;
  other_issues: string | null;
  image?: UserImageType | null;
  weight_in_kg: number | null;
  height_in_cm: number | null;
  perimeter_bicep_left: number | null;
  perimeter_bicep_right: number | null;
  perimeter_chest: number | null;
  perimeter_upper_leg_left: number | null;
  perimeter_upper_leg_right: number | null;
  perimeter_waist: number | null;
  perimeter_hip: number | null;
  perimeter_neck: number | null;
  perimeter_abdominal: number | null;
  visceral_fat: number | null;
  h2o: number | null;
  metabolic_age: number | null;
  bone_mineral_density: number | null;
  muscular_mass: number | null;
  basal_metabolic_rate: number | null;
  max_heart_freq: number | null;
  hip_waist_relation: number | null;
  objectives: string | null;
  availability: string | null;
  personal_trainer_id: string | null;
  has_cardiac_diseases: boolean;
  has_chest_pain_while_doing_sports: boolean;
  has_chest_pain_while_not_doing_sports: boolean;
  has_balance_loss: boolean;
  has_bones_issues: boolean;
  has_medication_for_blood_pressure: boolean;
  has_limitations_for_sports: boolean;
  cardiac_diseases: string | null;
  chest_pain_while_doing_sports: string | null;
  chest_pain_while_not_doing_sports: string | null;
  balance_loss: string | null;
  bones_issues: string | null;
  medication_for_blood_pressure: string | null;
  limitations_for_sports: string | null;
  practiced_sports_answer: string | null;
  practiced_sports: boolean;
  tourniquet_id?: string | null;
  identifier?: number | null;
  gender?: string | null;
  nif?: number | null;
  fiscal_address?: string | null;
  fiscal_zip_code?: string | null;
  fiscal_city?: string | null;
};

export type UpdateUserType = CreateUserType & {
  id: string;
  current_password: string | undefined;
  new_password: string | undefined;
};

const initialState: UserState = {
  currentUser: undefined,
  currentClient: undefined,
  users: [],
  isLoading: false,
  errorMessage: undefined,
  confirmationMessage: undefined,
  userCounters: [],
  usersWithMissingPayments: [],
  revenue: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    fetchUsers(state: UserState) {
      state.isLoading = true;
    },
    fetchUsersSucceeded(state: UserState, action: PayloadAction<Array<User>>) {
      state.users = action.payload;
      state.isLoading = false;
    },
    fetchUser(state: UserState, action: PayloadAction<string>) {},
    fetchUserSucceeded(state: UserState, action: PayloadAction<User>) {
      const userIndex = state.users.findIndex(
        (user: User) => user.id === action.payload.id
      );
      if (userIndex >= 0) {
        state.users[userIndex] = action.payload;
      } else {
        state.users = [action.payload];
      }
      state.isLoading = false;
    },
    fetchUserCounters(state: UserState) {},
    fetchUserCountersSucceeded(
      state: UserState,
      action: PayloadAction<Array<UserCounter>>
    ) {
      state.userCounters = action.payload;
    },
    fetchUsersWithMissingPayments(state: UserState) {},
    fetchUsersWithMissingPaymentsSucceeded(
      state: UserState,
      action: PayloadAction<Array<UserWithMissingPayments>>
    ) {
      state.usersWithMissingPayments = action.payload;
    },
    fetchRevenue(
      state: UserState,
      action: PayloadAction<FetchUserRevenueType>
    ) {},
    fetchRevenueSucceeded(state: UserState, action: PayloadAction<number>) {
      state.revenue = action.payload;
    },
    createUser(state: UserState, action: PayloadAction<CreateUserType>) {},
    createUserSucceeded(state: UserState, action: PayloadAction<User>) {
      return {
        ...state,
        confirmationMessage: userSuccessfullyCreated,
        users: [action.payload, ...state.users],
      };
    },
    updateUser(state: UserState, action: PayloadAction<UpdateUserType>) {},
    updateUserSucceeded(state: UserState, action: PayloadAction<User>) {
      const index = state.users.findIndex(
        (user: User) => user.id === action.payload.id
      );

      state.users[index] = action.payload;
      state.confirmationMessage = userSuccessfullyUpdated;
    },
    reInviteUser(state: UserState, action: PayloadAction<string>) {},
    reInviteUserSucceeded(state: UserState) {
      state.confirmationMessage = userSuccessfullyUpdated;
    },
    deleteUser(state: UserState, action: PayloadAction<string>) {},
    deleteUserSucceeded(state: UserState, action: PayloadAction<string>) {
      const index = state.users.findIndex(
        (user: User) => user.id === action.payload
      );

      state.users[index].status = "discarded";
      state.confirmationMessage = userSuccessfullyDeleted;
    },
    setCurrentUser(
      state: UserState,
      action: PayloadAction<string | undefined>
    ) {
      state.currentUser = state.users.find(
        (user: User) => action.payload === user.id
      );
    },
    setCurrentClient(
      state: UserState,
      action: PayloadAction<string | undefined>
    ) {
      state.currentClient = state.users.find(
        (user: User) => action.payload === user.id
      );
    },
    reActivateUser(state: UserState, action: PayloadAction<string>) {},
    reActivateUserSucceeded(state: UserState) {
      state.confirmationMessage = userSuccessfullyUpdated;
    },
    setErrorMessage(
      state: UserState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: UserState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchUsers,
  fetchUsersSucceeded,
  fetchUser,
  fetchUserSucceeded,
  createUser,
  createUserSucceeded,
  updateUser,
  updateUserSucceeded,
  deleteUser,
  deleteUserSucceeded,
  setCurrentUser,
  setCurrentClient,
  setErrorMessage,
  setConfirmationMessage,
  fetchUserCounters,
  fetchUserCountersSucceeded,
  reInviteUser,
  reInviteUserSucceeded,
  fetchUsersWithMissingPayments,
  fetchUsersWithMissingPaymentsSucceeded,
  fetchRevenue,
  fetchRevenueSucceeded,
  reActivateUser,
  reActivateUserSucceeded,
} = userSlice.actions;

export const selectUsers = (state: RootState) => state.userSlice.users;
export const selectErrorMessage = (state: RootState) =>
  state.userSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.userSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) => state.userSlice.isLoading;
export const selectCurrentUser = (state: RootState) =>
  state.userSlice.currentUser;
export const selectCurrentClient = (state: RootState) =>
  state.userSlice.currentClient;
export const selectUserCounters = (state: RootState) =>
  state.userSlice.userCounters;
export const selectUsersWithMissingPayments = (state: RootState) =>
  state.userSlice.usersWithMissingPayments;
export const selectRevenue = (state: RootState) => state.userSlice.revenue;

export default userSlice.reducer;
