import { useState } from "react";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { Food, Recipe } from "types/nutrition";
import { FaArrowAltCircleLeft } from "react-icons/fa";

import "components/NutritionPage/SetWeightAndShowMacros/SetWeightAndShowMacros.scss";

type SetWeightAndShowMacrosProps = {
  item: Food | Recipe;
  sendRecipe: (weight: number) => void;
  onCancelClick: () => void;
};

export const SetWeightAndShowMacros = ({
  item,
  sendRecipe,
  onCancelClick,
}: SetWeightAndShowMacrosProps) => {
  const [weight, setWeight] = useState(100);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(Number(e.target.value));
  };

  const handleSubmit = () => {
    sendRecipe(weight);
  };

  const isRecipe = (item: Food | Recipe): item is Recipe => {
    return "totals" in item;
  };

  return (
    <div className="set-weight-and-show-macros">
      <GButton
        className="back-button"
        variant="primary"
        onClick={onCancelClick}
      >
        <FaArrowAltCircleLeft />
      </GButton>
      <div className="item-name-container">
        <h4 className="item-name">
          {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
        </h4>
      </div>
      <GInput
        type="number"
        label="Peso (g)"
        value={weight}
        onChange={handleChange}
      />
      <GInput
        type="number"
        label="Calorias (kcal)"
        value={
          isRecipe(item)
            ? ((item as Recipe).totals.calories * (weight / 100)).toFixed(2)
            : ((item as Food).calories * (weight / 100)).toFixed(2)
        }
        disabled
      />
      <GInput
        type="number"
        label="Proteína (g)"
        value={
          isRecipe(item)
            ? ((item as Recipe).totals.protein * (weight / 100)).toFixed(2)
            : ((item as Food).protein * (weight / 100)).toFixed(2)
        }
        disabled
      />
      <GInput
        type="number"
        label="Hidratos (g)"
        value={
          isRecipe(item)
            ? ((item as Recipe).totals.carbs * (weight / 100)).toFixed(2)
            : ((item as Food).carbs * (weight / 100)).toFixed(2)
        }
        disabled
      />
      <GInput
        type="number"
        label="Lípidos (g)"
        value={
          isRecipe(item)
            ? ((item as Recipe).totals.lipids * (weight / 100)).toFixed(2)
            : ((item as Food).lipids * (weight / 100)).toFixed(2)
        }
        disabled
      />
      <div className="button-container">
        <GButton variant="primary" onClick={handleSubmit}>
          Submeter
        </GButton>
        <GButton variant="secondary" onClick={onCancelClick}>
          Cancelar
        </GButton>
      </div>
    </div>
  );
};
