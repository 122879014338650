import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { toast } from "react-toastify";
import { DashboardTitle } from "../../DashboardTitle/DashboardTitle";
import { selectUsers } from "../../../slices/userSlice";
import { SelectForm } from "../../SelectForm/SelectForm";
import { GymRoom } from "../../../types/gymRoom";
import { InputForm } from "../../InputForm/InputForm";
import { fetchGymRooms, selectGymRooms } from "../../../slices/gymRoomSlice";
import { fetchCourses, selectCourses } from "../../../slices/courseSlice";
import { DayPicker } from "react-day-picker";
import { pt } from "date-fns/locale";
import { MdDeleteForever } from "react-icons/md";
import {
  getHours,
  getMinutes,
  getYearAndMonthAndDay,
} from "../../../helpers/date";
import {
  setErrorMessage,
  setConfirmationMessage,
  selectErrorMessage,
  selectConfirmationMessage,
  createCourseSchedule,
  updateCourseSchedule,
  deleteCourseSchedule,
  fetchCourseSchedule,
  selectCoursesSchedules,
} from "../../../slices/courseScheduleSlice";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { CourseSchedule } from "../../../types/courseSchedule";
import { User } from "../../../types/user";
import { Course } from "../../../types/course";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
} from "../../../slices/personalTrainerSlice";

import "./CalendarPage.css";
import { CalendarModal } from "./CalendarModal/CalendarModal";
import { GButton } from "components/genericComponents/GButton/GButton";
import { formatToFirstTwoAndLastName } from "helpers/utils";

export type SelectDataType = {
  value: string;
  label: string;
};

type Weekdays =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export const CalendarPage = () => {
  ReactGA.send("/workout-details");

  let navigate = useNavigate();
  const url = window.location.href.split("/").slice(-1)[0];
  const scheduleId = url.split("?")[0];
  const params = url.split("?")[1];
  const [startAt, setStartAt] = useState<any | null>(
    new Date(params.split("&")[0].split("=")[1])
  );
  const [endAt, setEndAt] = useState<any | null>(
    new Date(params.split("&")[1].split("=")[1])
  );
  const isGymView = localStorage.getItem("isGymView") === "true";
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const courseSchedules = useSelector(selectCoursesSchedules);
  const isCreatingSchedule = scheduleId === "add-schedule";
  const [isNotEditing, setIsNotEditing] = useState<boolean>(
    !isCreatingSchedule
  );
  const selectedCourseSchedule =
    courseSchedules.find((courseSchedule: CourseSchedule) => {
      return courseSchedule.id === scheduleId;
    }) ?? undefined;
  const [selectedUsers, setSelectedUsers] = useState<User[]>(
    selectedCourseSchedule?.users ?? []
  );
  const [selectedPersonalTrainer, setSelectedPersonalTrainer] =
    useState<User | null>(selectedCourseSchedule?.personal_trainer ?? null);
  const [slotsTotal, setSlotsTotal] = useState<number | null>(
    selectedCourseSchedule?.slots_total ?? null
  );
  const [level, setLevel] = useState<string | null>(
    selectedCourseSchedule?.level ?? null
  );
  const [course, setCourse] = useState<Course | null>(
    selectedCourseSchedule?.course ?? null
  );
  const [title, setTitle] = useState<string | null>(
    selectedCourseSchedule?.title ?? null
  );
  const [selectedGymRoom, setSelectedGymRoom] = useState<GymRoom | null>(
    selectedCourseSchedule?.gym_room ?? null
  );
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(
    selectedCourseSchedule?.course ?? null
  );
  const [titleError, setTitleError] = useState<string | undefined>(undefined);
  const [slotsTotalError, setSlotsTotalError] = useState<string | undefined>(
    undefined
  );
  const [roomError, setRoomError] = useState<string | undefined>(undefined);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [courseScheduleFetched, setCourseScheduleFetched] = useState(false);
  const gymRooms = useSelector(selectGymRooms);
  const courses = useSelector(selectCourses);
  const personal_trainers = useSelector(selectPersonalTrainers);
  const [isRecurring, setIsRecurring] = useState<boolean>(
    selectedCourseSchedule ? selectedCourseSchedule.is_recurring : false
  );
  const [selectedWeekdays, setSelectedWeekdays] = useState<Weekdays[]>([]);
  const [repeatingWeeks, setRepeatingWeeks] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [extraClients, setExtraClients] = useState<number>(0);
  const weekDays: Array<Weekdays> = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const weekDaysLabel = [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
    "Domingo",
  ];

  const isCourseSchedule =
    (isGymView && selectedCourseSchedule === undefined) ||
    (selectedCourseSchedule?.course !== null &&
      selectedCourseSchedule?.course !== undefined);

  const getDateHours = (date: Date | null): string => {
    if (date === null) return "";

    return getHours(date);
  };

  const getDateMinutes = (date: Date | null): string => {
    if (date === null) return "";

    return getMinutes(date);
  };

  useEffect(() => {
    setSlotsTotal(selectedCourseSchedule?.slots_total ?? null);
    setLevel(selectedCourseSchedule?.level ?? null);
    setCourse(selectedCourseSchedule?.course ?? null);
    setTitle(selectedCourseSchedule?.title ?? null);
    setSelectedUsers(selectedCourseSchedule?.users ?? []);
    setSelectedGymRoom(selectedCourseSchedule?.gym_room ?? null);
    setSelectedCourse(selectedCourseSchedule?.course ?? null);
    setSelectedPersonalTrainer(
      selectedCourseSchedule?.personal_trainer ?? null
    );
    setExtraClients(selectedCourseSchedule?.other_participants_count ?? 0);
  }, [selectedCourseSchedule]);

  useEffect(() => {
    dispatch(fetchGymRooms());
    dispatch(fetchCourses());
    dispatch(fetchPersonalTrainers());
  }, [dispatch]);

  useEffect(() => {
    if (courseScheduleFetched) return;

    if (!isCreatingSchedule) {
      dispatch(fetchCourseSchedule(scheduleId));
      setCourseScheduleFetched(true);
    }
  }, [
    dispatch,
    selectedCourseSchedule,
    courseScheduleFetched,
    isCreatingSchedule,
    scheduleId,
  ]);

  const onSubmitButtonClick = (event: any) => {
    var isValid = true;

    if (selectedCourse !== null) {
      if (!isSlotsTotalValid()) isValid = false;
      if (!isRoomValid()) isValid = false;
    } else {
      if (!isTitleValid()) isValid = false;
    }

    if (!isValid) return;

    if (selectedCourseSchedule === undefined) {
      if (isRecurring) {
        dispatch(
          createCourseSchedule({
            slots_total: slotsTotal ?? undefined,
            level: level ?? undefined,
            start_at: startAt!,
            end_at: endAt!,
            course_id: selectedCourse?.id,
            user_ids: selectedUsers.map((user: User) => user.id),
            title: course?.title ?? title!,
            company_room_id: selectedGymRoom?.id ?? undefined,
            recurrence: {
              week_days: selectedWeekdays,
              week_recurrence: repeatingWeeks,
            },
            other_participants_count: extraClients,
          })
        );
      } else {
        dispatch(
          createCourseSchedule({
            slots_total: slotsTotal ?? undefined,
            level: level ?? undefined,
            start_at: startAt!,
            end_at: endAt!,
            course_id: selectedCourse?.id,
            user_ids: selectedUsers.map((user: User) => user.id),
            title: course?.title ?? title!,
            company_room_id: selectedGymRoom?.id ?? undefined,
            other_participants_count: extraClients,
          })
        );
      }
    } else {
      if (isRecurring) {
        dispatch(
          updateCourseSchedule({
            id: selectedCourseSchedule!.id,
            slots_total: slotsTotal ?? undefined,
            level: level ?? undefined,
            start_at: startAt!,
            end_at: endAt!,
            course_id: selectedCourse?.id,
            user_ids: selectedUsers.map((user: User) => user.id),
            title: selectedCourse?.title ?? title!,
            company_room_id: selectedGymRoom?.id ?? undefined,
            recurrence: {
              week_days: selectedWeekdays,
              week_recurrence: repeatingWeeks,
            },
            other_participants_count: extraClients,
          })
        );
      } else {
        dispatch(
          updateCourseSchedule({
            id: selectedCourseSchedule!.id,
            slots_total: slotsTotal ?? undefined,
            level: level ?? undefined,
            start_at: startAt!,
            end_at: endAt!,
            course_id: selectedCourse?.id,
            user_ids: selectedUsers.map((user: User) => user.id),
            title: selectedCourse?.title ?? title!,
            company_room_id: selectedGymRoom?.id ?? undefined,
            other_participants_count: extraClients,
          })
        );
      }
    }
    navigate(-1);
  };

  const onDeleteButtonClick = () => {
    if (selectedCourseSchedule?.is_recurring) {
      setShowModal(true);
    } else {
      dispatch(
        deleteCourseSchedule({
          id: selectedCourseSchedule!.id,
          delete_rule: isRecurring ? true : false,
        })
      );
      navigate(-1);
    }
  };

  const onSubmitModalButtonClick = (singleEvent: boolean) => {
    dispatch(
      deleteCourseSchedule({
        id: selectedCourseSchedule!.id,
        delete_rule: singleEvent ? false : true,
      })
    );
    setShowModal(false);
    navigate(-1);
  };

  const isTitleValid = (): boolean => {
    if (title === null) {
      setTitleError("Por favor insira o título");
      return false;
    }

    setTitleError(undefined);
    return true;
  };

  const isRoomValid = (): boolean => {
    if (selectedGymRoom === null) {
      setRoomError("Por favor escolha a sala");
      return false;
    }

    setRoomError(undefined);
    return true;
  };

  const isSlotsTotalValid = (): boolean => {
    if (slotsTotal === null) {
      setSlotsTotalError("Por favor insira o número máximo de utilizadores");
      return false;
    }

    setSlotsTotalError(undefined);
    return true;
  };

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "calendar-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "calendar-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      setIsNotEditing(true);
    }
  }, [dispatch, confirmationMessage]);

  return (
    <Container fluid id="workout-page">
      <DashboardTitle
        title={
          (isCourseSchedule ? selectedCourse?.title : title) ??
          "Criar um evento"
        }
        showAddButton={false}
      />
      <Container fluid id="workout-page-outer-container">
        <Container fluid className="workout-page-container">
          {!isCreatingSchedule && (
            <Row>
              <Col className="workout-page-edit-training-plan-col">
                {selectedCourseSchedule && isNotEditing && (
                  <Button
                    className="calendar-page-delete-icon"
                    onClick={(e: any) => onDeleteButtonClick()}
                  >
                    <MdDeleteForever />
                  </Button>
                )}
                {selectedCourseSchedule && isNotEditing ? (
                  <Button
                    className="personal-data-edit-button"
                    onClick={() => setIsNotEditing(!isNotEditing)}
                  >
                    <BsPencilSquare />
                  </Button>
                ) : (
                  <Button
                    className="personal-data-edit-button-close"
                    onClick={() => setIsNotEditing(!isNotEditing)}
                  >
                    <BsFillXCircleFill />
                  </Button>
                )}
              </Col>
            </Row>
          )}
          <Row>
            {isCourseSchedule && (
              <Col>
                <SelectForm
                  disabled={isNotEditing}
                  isClearable={true}
                  options={courses.map((course: Course) => {
                    return {
                      id: course.id!,
                      title: course.title!,
                    };
                  })}
                  value={
                    selectedCourse === undefined || selectedCourse === null
                      ? undefined
                      : {
                          id: selectedCourse.id!,
                          title: selectedCourse.title!,
                        }
                  }
                  label="Aula"
                  onChange={(e: any) => {
                    if (e === null) {
                      setSelectedCourse(null);
                    } else {
                      setSelectedCourse(
                        courses.find((course: Course) => course.id === e.value)!
                      );
                    }
                  }}
                />
              </Col>
            )}
            {!isCourseSchedule && (
              <Col>
                <InputForm
                  value={title}
                  label="Título"
                  errorMessage={titleError}
                  disabled={isNotEditing}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
              </Col>
            )}
          </Row>
          <Row>
            {isCourseSchedule && (
              <Col xs={12} lg={4}>
                <InputForm
                  value={slotsTotal}
                  label="Número máximo de utilizadores"
                  type="number"
                  disabled={isNotEditing}
                  errorMessage={slotsTotalError}
                  onChange={(e: any) => setSlotsTotal(e.target.value)}
                />
              </Col>
            )}
            <Col xs={12} lg={4}>
              <SelectForm
                disabled={isNotEditing}
                isClearable={true}
                options={gymRooms.map((gymRoom: GymRoom) => {
                  return {
                    id: gymRoom.id!,
                    title: gymRoom.title!,
                  };
                })}
                value={
                  selectedGymRoom === undefined || selectedGymRoom === null
                    ? undefined
                    : {
                        id: selectedGymRoom.id!,
                        title: selectedGymRoom.title!,
                      }
                }
                label="Divisão"
                onChange={(e: any) => {
                  if (e === null) {
                    setSelectedGymRoom(null);
                  } else {
                    setSelectedGymRoom(
                      gymRooms.find(
                        (gymRoom: GymRoom) => gymRoom.id === e.value
                      )!
                    );
                  }
                }}
                errorMessage={roomError}
              />
            </Col>
            {isCourseSchedule && (
              <Col xs={12} lg={4}>
                <SelectForm
                  isClearable={true}
                  disabled={!isGymView || isNotEditing}
                  options={personal_trainers.map((personal_trainer: User) => {
                    return {
                      id: personal_trainer.id!,
                      title: formatToFirstTwoAndLastName(personal_trainer.name),
                      image: personal_trainer.image_small,
                    };
                  })}
                  value={
                    selectedPersonalTrainer === undefined ||
                    selectedPersonalTrainer === null
                      ? undefined
                      : {
                          id: selectedPersonalTrainer.id!,
                          title: formatToFirstTwoAndLastName(
                            selectedPersonalTrainer.name
                          ),
                          image: selectedPersonalTrainer.image_small,
                        }
                  }
                  hasImage={true}
                  label="Personal Trainer"
                  onChange={(e: any) => {
                    if (e === null) {
                      setSelectedPersonalTrainer(null);
                    } else {
                      setSelectedPersonalTrainer(
                        personal_trainers.find(
                          (personal_trainer: User) =>
                            personal_trainer.id === e.value
                        )!
                      );
                    }
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            {!isCreatingSchedule && (
              <Col className="mb-3" xs={12} lg={4}>
                <Button
                  className="course-schedule-modal-calendar-date-picker"
                  disabled={isNotEditing}
                  onClick={() => setShowDayPicker(!showDayPicker)}
                >
                  {startAt !== undefined && startAt !== null
                    ? getYearAndMonthAndDay(startAt)
                    : undefined}
                </Button>
                {showDayPicker ? (
                  <div className="course-schedule-modal-calendar-div">
                    <DayPicker
                      mode="single"
                      selected={startAt}
                      locale={pt}
                      onSelect={(e: Date | undefined) => {
                        if (e === undefined) return;
                        const day = e.getDate();
                        const month = e.getMonth();
                        const year = e.getFullYear();

                        startAt.setDate(day);
                        startAt.setMonth(month);
                        startAt.setFullYear(year);

                        endAt.setDate(day);
                        endAt.setMonth(month);
                        endAt.setFullYear(year);

                        setStartAt(new Date(startAt));
                        setEndAt(new Date(endAt));
                        setShowDayPicker(false);
                      }}
                    />
                  </div>
                ) : undefined}
              </Col>
            )}
            <Col className="mb-3" xs={12} lg={4}>
              <input
                type="time"
                required
                min="09:00"
                max="18:00"
                disabled={isNotEditing}
                className="course-schedule-modal-hours"
                value={`${getDateHours(startAt)}:${getDateMinutes(startAt)}`}
                onChange={(e) => {
                  const hoursAndMinutes = e.target.value.split(":");
                  var hours = Number(hoursAndMinutes[0]);
                  var minutes = Number(hoursAndMinutes[1]);

                  if (hours < 5) {
                    hours = 5;
                  } else if (hours > 22) {
                    hours = 22;
                  }

                  if (hours === 22) minutes = 0;

                  startAt.setHours(hours);
                  startAt.setMinutes(minutes);

                  setStartAt(new Date(startAt));
                }}
              />
            </Col>
            <Col className="mb-3" xs={12} lg={4}>
              <input
                type="time"
                required
                disabled={isNotEditing}
                className="course-schedule-modal-hours"
                value={`${getDateHours(endAt)}:${getDateMinutes(endAt)}`}
                onChange={(e) => {
                  const hoursAndMinutes = e.target.value.split(":");
                  var hours = Number(hoursAndMinutes[0]);
                  var minutes = Number(hoursAndMinutes[1]);

                  if (hours < 5) {
                    hours = 5;
                  } else if (hours > 22) {
                    hours = 22;
                  }

                  if (hours === 22) minutes = 0;

                  endAt.setHours(hours);
                  endAt.setMinutes(minutes);
                  setEndAt(new Date(endAt));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputForm
                value={extraClients}
                label="Número de clientes externos"
                type="number"
                disabled={isNotEditing}
                onChange={(e: any) => setExtraClients(e.target.value)}
              />
            </Col>
          </Row>
          {!isNotEditing && (
            <Row>
              <Col>
                <SelectForm
                  isClearable
                  label="Clientes"
                  height="10rem"
                  hasImage={true}
                  isMultiSelect={true}
                  multiValue={
                    selectedUsers.length === 0
                      ? undefined
                      : selectedUsers.map((user: User) => {
                          return {
                            id: `${user.id}`,
                            title: formatToFirstTwoAndLastName(user.name),
                            image:
                              user.image_small ??
                              "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png",
                          };
                        })
                  }
                  onChange={(e: any) => {
                    setSelectedUsers(
                      e
                        .map((value: any) => {
                          return users.find(
                            (user: User) => user.id === value.value
                          );
                        })
                        .filter((value: any) => value !== undefined)
                    );
                  }}
                  options={users.map((user: User) => {
                    return {
                      id: `${user.id!}`,
                      title: user.name!,
                      image:
                        user.image_small ??
                        "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png",
                    };
                  })}
                />
              </Col>
            </Row>
          )}
          {!isNotEditing && (
            <Row>
              <Col>
                <Form.Check
                  checked={isRecurring}
                  disabled={!isNotEditing && !isCreatingSchedule}
                  type="switch"
                  label="É um evento recorrente?"
                  className="purple-switch"
                  onChange={() => {
                    setIsRecurring(!isRecurring);
                  }}
                />
              </Col>
            </Row>
          )}
          {isCreatingSchedule && (
            <div>
              {!isRecurring ? (
                <Row>
                  <Col className="mb-3" xs={12} lg={4}>
                    <Button
                      className="course-schedule-modal-calendar-date-picker"
                      disabled={isNotEditing}
                      onClick={() => {
                        if (isCreatingSchedule)
                          setShowDayPicker(!showDayPicker);
                      }}
                    >
                      {startAt !== undefined && startAt !== null
                        ? getYearAndMonthAndDay(startAt)
                        : undefined}
                    </Button>
                    {showDayPicker && isCreatingSchedule ? (
                      <div className="course-schedule-modal-calendar-div">
                        <DayPicker
                          mode="single"
                          selected={startAt}
                          locale={pt}
                          onSelect={(e: Date | undefined) => {
                            if (e === undefined) return;
                            const day = e.getDate();
                            const month = e.getMonth();
                            const year = e.getFullYear();

                            startAt.setDate(day);
                            startAt.setMonth(month);
                            startAt.setFullYear(year);

                            endAt.setDate(day);
                            endAt.setMonth(month);
                            endAt.setFullYear(year);

                            setStartAt(new Date(startAt));
                            setEndAt(new Date(endAt));
                            setShowDayPicker(false);
                          }}
                        />
                      </div>
                    ) : undefined}
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col className="weekdays-repetition-col">
                      <p className="weekdays-title">Repetir a cada</p>
                      <input
                        type="number"
                        min={1}
                        className="weekdays-input"
                        disabled={!isNotEditing && !isCreatingSchedule}
                        value={repeatingWeeks}
                        onChange={(e) =>
                          setRepeatingWeeks(Number.parseInt(e.target.value))
                        }
                      />
                      <p className="weekdays-title">semanas</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className="weekdays-title">
                        Dias da semana a repetir
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="checkbox-btns-col">
                      {weekDays.map((weekDayLabel: Weekdays, index: number) => (
                        <Button
                          className={`checkbox-btn${
                            selectedWeekdays.includes(weekDayLabel)
                              ? " checked"
                              : ""
                          }${
                            !isNotEditing && !isCreatingSchedule
                              ? " editing"
                              : ""
                          }`}
                          onClick={() => {
                            if (isCreatingSchedule && !isNotEditing)
                              if (selectedWeekdays.includes(weekDayLabel)) {
                                setSelectedWeekdays(
                                  selectedWeekdays.filter(
                                    (weekday) => weekday !== weekDayLabel
                                  )
                                );
                              } else {
                                setSelectedWeekdays([
                                  ...selectedWeekdays,
                                  weekDayLabel,
                                ]);
                              }
                          }}
                        >
                          {weekDaysLabel[index]}
                        </Button>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
          {isNotEditing && selectedCourseSchedule && (
            <Row style={{ marginTop: 40 }}>
              <Col>
                <h4 style={{ textAlign: "center", marginBottom: 30 }}>
                  Clientes Inscritos
                </h4>
                <div className="signup-users">
                  {selectedCourseSchedule?.users &&
                    selectedCourseSchedule.users.length === 0 && (
                      <p className="signup-users-text">
                        Nenhum cliente inscrito
                      </p>
                    )}
                  {selectedCourseSchedule?.users?.map((user) => {
                    return (
                      <div className="signup-user-div">
                        <img
                          src={
                            user.image_small ??
                            "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                          }
                          alt="something"
                          height="30px"
                          width="30px"
                          className="select-img"
                        />{" "}
                        {formatToFirstTwoAndLastName(user.name)}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="submit-exercise-col">
              {!isNotEditing && (
                <GButton label="Submeter" onClick={onSubmitButtonClick} />
              )}
            </Col>
          </Row>
        </Container>
      </Container>
      <CalendarModal
        showModal={showModal}
        onSubmitModalButtonClick={onSubmitModalButtonClick}
      />
    </Container>
  );
};
