import { GSection } from "components/genericComponents/GSection/GSection";
import { addDays, format, subDays } from "date-fns";
import { useEffect, useState } from "react";

import "components/DashboardCustomersPage/ClientNutritionPage/ClientNutritionPage.scss";
import { GButton } from "components/genericComponents/GButton/GButton";
import { FaAngleLeft, FaAngleRight, FaTrash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserNutritionSchedulerItem,
  createUserNutritionSchedulerNote,
  deleteUserNutritionSchedulerItem,
  deleteUserNutritionSchedulerNote,
  selectConfirmationMessage,
  selectErrorMessage,
  selectIsLoading,
  setConfirmationMessage,
  setErrorMessage,
} from "slices/nutritionSchedulerSlice";
import {
  selectUserNutritionScheduler,
  fetchUserNutritionScheduler,
} from "slices/nutritionSlice";
import { selectCurrentClient } from "slices/userSlice";
import { MdAdd } from "react-icons/md";
import { Food, Recipe, UserNutritionScheduler } from "types/nutrition";
import { GInput } from "components/genericComponents/GInput/GInput";
import { toast } from "react-toastify";
import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { AddMealModal } from "components/NutritionPage/AddMealModal/AddMealModal";
import tinycolor from "tinycolor2";

export const ClientNutritionPage = () => {
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectCurrentClient);
  const selectedUserNutrition = useSelector(selectUserNutritionScheduler);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const isLoading = useSelector(selectIsLoading);
  const today = new Date();
  const [currentDays, setCurrentDays] = useState([
    subDays(today, 1),
    today,
    addDays(today, 1),
  ]);
  const [activeAddMealModal, setActiveAddMealModal] = useState<
    number | undefined
  >(undefined);
  const [showAddMealModal, setShowAddMealModal] = useState(false);
  const [wasFetched, setWasFetched] = useState(false);
  const [formData, setFormData] = useState<UserNutritionScheduler[]>(
    selectedUserNutrition || []
  );
  const [notes, setNotes] = useState([
    {
      note: "",
      show: false,
    },
    {
      note: "",
      show: false,
    },
    {
      note: "",
      show: false,
    },
  ]);

  const subtractDay = () => {
    dispatch(
      fetchUserNutritionScheduler({
        user_id: selectedUser!.id,
        from: subDays(currentDays[0], 3).toISOString(),
        to: subDays(currentDays[2], 3).toISOString(),
      })
    );
    setCurrentDays([
      subDays(currentDays[0], 3),
      subDays(currentDays[1], 3),
      subDays(currentDays[2], 3),
    ]);
  };

  const addDay = () => {
    dispatch(
      fetchUserNutritionScheduler({
        user_id: selectedUser!.id,
        from: addDays(currentDays[0], 3).toISOString(),
        to: addDays(currentDays[2], 3).toISOString(),
      })
    );
    setCurrentDays([
      addDays(currentDays[0], 3),
      addDays(currentDays[1], 3),
      addDays(currentDays[2], 3),
    ]);
  };

  const addNote = (itemIdx: number) => {
    dispatch(
      createUserNutritionSchedulerNote({
        user_id: selectedUser!.id,
        date: currentDays[itemIdx].toISOString(),
        note: notes[itemIdx].note,
      })
    );
  };

  const showAddNote = (itemIdx: number) => {
    setNotes((prev) => {
      const newNotes = [...prev];
      newNotes[itemIdx].show = true;
      return newNotes;
    });
  };

  const cancelAddNote = (itemIdx: number) => {
    setNotes((prev) => {
      const newNotes = [...prev];
      newNotes[itemIdx].note = "";
      newNotes[itemIdx].show = false;
      return newNotes;
    });
  };

  const addRecipe = (itemIdx: number) => {
    setActiveAddMealModal(itemIdx);
    setShowAddMealModal(true);
  };

  const setIngredient = (weight: number, ingredient: Food) => {
    dispatch(
      createUserNutritionSchedulerItem({
        weight_in_g: weight.toString(),
        date: currentDays[activeAddMealModal!]?.toISOString(),
        user_id: selectedUser!.id,
        item_id: ingredient.id,
        item_type: "food",
      })
    );
  };

  const setRecipe = (weight: number, recipe: Recipe) => {
    dispatch(
      createUserNutritionSchedulerItem({
        weight_in_g: weight.toString(),
        date: currentDays[activeAddMealModal!]?.toISOString(),
        user_id: selectedUser!.id,
        item_id: recipe.id,
        item_type: "recipe",
      })
    );
  };

  const deleteItem = (itemOverviewIdx: number, itemIdx: number) => {
    dispatch(
      deleteUserNutritionSchedulerItem(
        formData[itemOverviewIdx].items[itemIdx].id!
      )
    );
  };

  const deleteNote = (itemIdx: number, noteIdx: number) => {
    dispatch(
      deleteUserNutritionSchedulerNote(formData[itemIdx].notes[noteIdx].id!)
    );
  };

  useEffect(() => {
    if (selectedUser && currentDays && !wasFetched) {
      dispatch(
        fetchUserNutritionScheduler({
          user_id: selectedUser!.id,
          from: currentDays[0].toISOString(),
          to: currentDays[2].toISOString(),
        })
      );
      setWasFetched(true);
    }
  }, [dispatch, selectedUser, currentDays, wasFetched]);

  useEffect(() => {
    setFormData(selectedUserNutrition || []);
  }, [selectedUserNutrition]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "course-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "course-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setNotes((prev) => {
        const newNotes = [...prev];
        newNotes.forEach((note) => {
          note.note = "";
          note.show = false;
        });
        return newNotes;
      });
      dispatch(setConfirmationMessage(undefined));
      dispatch(
        fetchUserNutritionScheduler({
          user_id: selectedUser!.id,
          from: currentDays[0].toISOString(),
          to: currentDays[2].toISOString(),
        })
      );
    }
  }, [dispatch, confirmationMessage, currentDays, selectedUser]);

  return (
    <GSection className="client-nutrition-page" label="Calendário de refeições">
      {isLoading ? (
        <SpinnerWrapper />
      ) : (
        <div className="g-section-content">
          <div className="grid-container">
            {/* HEADER */}
            <div className="grid-item">
              <GButton
                className="arrow-button arrow-button-left"
                variant="transparent"
                onClick={() => subtractDay()}
              >
                <FaAngleLeft />
              </GButton>
              <p className="date-label">{format(currentDays[0], "dd MMM")}</p>
            </div>
            <div className="grid-item">
              <p className="date-label">{format(currentDays[1], "dd MMM")}</p>
            </div>
            <div className="grid-item">
              <p className="date-label">{format(currentDays[2], "dd MMM")}</p>
              <GButton
                className="arrow-button arrow-button-right"
                variant="transparent"
                onClick={() => addDay()}
              >
                <FaAngleRight />
              </GButton>
            </div>

            {/* RECIPE CARDS */}
            {formData.map((itemOverview, itemOverviewIdx) => (
              <div className="grid-item recipe-card-container">
                <div className="recipe-card-inner-container">
                  {itemOverview.items.map((item, itemIdx) => (
                    <div
                      key={itemIdx}
                      className={`recipe-card-item recipe-card-item-${itemIdx}`}
                      onMouseEnter={(e) => {
                        (
                          e.currentTarget.querySelector(
                            `.dynamic-button-${itemIdx}`
                          ) as HTMLElement
                        ).style.display = "block";
                      }}
                      onMouseLeave={(e) => {
                        (
                          e.currentTarget.querySelector(
                            `.dynamic-button-${itemIdx}`
                          ) as HTMLElement
                        ).style.display = "none";
                      }}
                    >
                      <p>{item.item.name}</p>
                      <p>{item.weight_in_g}g</p>
                      <GButton
                        variant="delete"
                        className={`dynamic-button dynamic-button-${itemIdx}`}
                        style={{ display: "none" }}
                        onClick={() => deleteItem(itemOverviewIdx, itemIdx)}
                      >
                        <FaTrash />
                      </GButton>
                    </div>
                  ))}
                </div>
                <GButton
                  variant="add"
                  onClick={() => addRecipe(itemOverviewIdx)}
                >
                  <MdAdd />
                </GButton>
              </div>
            ))}

            {/* TOTALS */}
            {formData?.map((item) => (
              <div className="grid-item totals-container">
                <p className="total-label">Total</p>
                <div className="total-items-container">
                  <div
                    className="total-container"
                    style={{
                      background: `linear-gradient(to bottom, ${tinycolor(
                        "#4CAF50"
                      ).brighten()} 0%, #4CAF50 100%)`,
                    }}
                  >
                    <p className="total-value">
                      {item.items
                        .reduce((partialSum, a) => {
                          if (a.item.type === "food") {
                            return partialSum + (a.item as Food).calories;
                          } else {
                            return (
                              partialSum + (a.item as Recipe).totals.calories
                            );
                          }
                        }, 0)
                        .toFixed(0)}
                      <span className="total-unit">kcal</span>
                    </p>
                    <p>Calorias</p>
                  </div>
                  <div
                    className="total-container"
                    style={{
                      background: `linear-gradient(to bottom, ${tinycolor(
                        "#9C27B0"
                      ).brighten()} 0%, #9C27B0 100%)`,
                    }}
                  >
                    <p className="total-value">
                      {item.items
                        .reduce((partialSum, a) => {
                          if (a.item.type === "food") {
                            return partialSum + (a.item as Food).protein;
                          } else {
                            return (
                              partialSum + (a.item as Recipe).totals.protein
                            );
                          }
                        }, 0)
                        .toFixed(0)}
                      <span className="total-unit">g</span>
                    </p>
                    <p>Proteína</p>
                  </div>
                  <div
                    className="total-container"
                    style={{
                      background: `linear-gradient(to bottom, ${tinycolor(
                        "#03A9F4"
                      ).brighten()} 0%, #03A9F4 100%)`,
                    }}
                  >
                    <p className="total-value">
                      {item.items
                        .reduce((partialSum, a) => {
                          if (a.item.type === "food") {
                            return partialSum + (a.item as Food).carbs;
                          } else {
                            return partialSum + (a.item as Recipe).totals.carbs;
                          }
                        }, 0)
                        .toFixed(0)}
                      <span className="total-unit">g</span>
                    </p>
                    <p>Hidratos</p>
                  </div>
                  <div
                    className="total-container"
                    style={{
                      background: `linear-gradient(to bottom, ${tinycolor(
                        "#FF9800"
                      ).brighten()} 0%, #FF9800 100%)`,
                    }}
                  >
                    <p className="total-value">
                      {item.items
                        .reduce((partialSum, a) => {
                          if (a.item.type === "food") {
                            return partialSum + (a.item as Food).lipids;
                          } else {
                            return (
                              partialSum + (a.item as Recipe).totals.lipids
                            );
                          }
                        }, 0)
                        .toFixed(0)}
                      <span className="total-unit">g</span>
                    </p>
                    <p>Lípidos</p>
                  </div>
                </div>
              </div>
            ))}

            {/* NOTES */}
            {selectedUserNutrition?.map((item, itemIdx) => (
              <div className="grid-item notes-container">
                <p className="notes-label">Notas</p>
                {item.notes.map((note, noteIdx) => (
                  <div
                    key={noteIdx}
                    className="note-item"
                    onMouseEnter={(e) => {
                      (
                        e.currentTarget.querySelector(
                          `.delete-note-button-${itemIdx}`
                        ) as HTMLElement
                      ).style.display = "block";
                    }}
                    onMouseLeave={(e) => {
                      (
                        e.currentTarget.querySelector(
                          `.delete-note-button-${itemIdx}`
                        ) as HTMLElement
                      ).style.display = "none";
                    }}
                  >
                    <p>{note.date}</p>
                    <p>{note.note}</p>
                    <GButton
                      variant="delete"
                      className={`delete-note-button delete-note-button-${itemIdx}`}
                      style={{ display: "none" }}
                      onClick={() => deleteNote(itemIdx, noteIdx)}
                    >
                      <FaTrash />
                    </GButton>
                  </div>
                ))}
                {notes[itemIdx].show && (
                  <div>
                    <GInput
                      className="note-input"
                      value={notes[itemIdx].note}
                      onChange={(e: any) =>
                        setNotes((prev) => {
                          const newNotes = [...prev];
                          newNotes[itemIdx].note = e.target.value;
                          return newNotes;
                        })
                      }
                    />
                    <div className="note-buttons">
                      <GButton
                        variant="primary"
                        onClick={() => addNote(itemIdx)}
                      >
                        Submeter
                      </GButton>
                      <GButton
                        variant="secondary"
                        onClick={() => cancelAddNote(itemIdx)}
                      >
                        Cancelar
                      </GButton>
                    </div>
                  </div>
                )}
                {!notes[itemIdx].show && (
                  <GButton variant="add" onClick={() => showAddNote(itemIdx)}>
                    <MdAdd />
                  </GButton>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <AddMealModal
        showModal={showAddMealModal}
        setShowModal={setShowAddMealModal}
        ingredients={formData[activeAddMealModal!]?.items
          .filter((item) => item.item.type === "food")
          .map((item) => item.item as Food)}
        sendIngredient={setIngredient}
        sendRecipe={setRecipe}
      />
    </GSection>
  );
};
