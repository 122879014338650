import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { debounce } from "helpers/utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchFood,
  selectFoods,
  selectIsLoading,
} from "slices/nutritionSchedulerSlice";
import { Food } from "types/nutrition";

type AddIngredientModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  ingredients: Food[];
  setIngredient: (ingredient: Food) => void;
};

type FoodWithSelection = Food & { isSelected: boolean };

export const IngredientModalLogic = ({
  showModal,
  setShowModal,
  ingredients,
  setIngredient,
}: AddIngredientModalI) => {
  const dispatch = useDispatch();
  const foods = useSelector(selectFoods);
  const isLoading = useSelector(selectIsLoading);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedIngredients, setSearchedIngredients] = useState<
    FoodWithSelection[]
  >([]);

  useEffect(() => {
    dispatch(searchFood(null));
  }, [dispatch]);

  useEffect(() => {
    // First, create a map of ingredients with isSelected set to true
    const initialIngredients =
      ingredients?.reduce((acc, food) => {
        acc[food.id] = { ...food, isSelected: true };
        return acc;
      }, {} as { [key: string]: FoodWithSelection }) ?? {};

    // Then, merge this map with the current searchedIngredients
    const mergedIngredients =
      foods?.map((ingredient) => {
        // If the ingredient exists in initialIngredients, use that, otherwise use the existing ingredient
        return (
          initialIngredients[ingredient.id] || {
            ...ingredient,
            isSelected: false,
          }
        );
      }) || [];

    // Finally, set the searchedIngredients state with the merged ingredients
    setSearchedIngredients(mergedIngredients);
  }, [foods, ingredients]); // eslint-disable-line

  useEffect(() => {
    if (!showModal) {
      setSearchQuery("");
      setSearchedIngredients([]);
    }
  }, [showModal]); // eslint-disable-line

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchFood(query));
      }, 500),
    [dispatch]
  );

  const searchIngredient = (query: string) => {
    debouncedSearch(query);
  };

  return (
    <div className="add-ingredient-modal">
      <GInput
        autoFocus
        value={searchQuery}
        onChange={(e: any) => {
          setSearchQuery(e.target.value);
          searchIngredient(e.target.value);
        }}
        label="Pesquisar..."
      />
      {isLoading ? (
        <div className="loading-div">
          <SpinnerWrapper />
        </div>
      ) : (
        <div className="ingredient-list">
          {searchedIngredients.map((ingredient: FoodWithSelection) => {
            return (
              <GButton
                className="ingredient-button"
                key={ingredient.id}
                onClick={() => setIngredient(ingredient)}
                variant={ingredient.isSelected ? "secondary" : "primary"}
                disabled={ingredient.isSelected}
              >
                <div className="ingredient-info">
                  <p>{ingredient.name}</p>
                </div>
              </GButton>
            );
          })}
        </div>
      )}
    </div>
  );
};
