import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import ReactGA from "react-ga4";
import {
  emailMessage,
  emailValidMessage,
  nameMessage,
} from "../../../helpers/messages";
import { validEmailRegex } from "../../../helpers/validation";
import { selectCurrentUser } from "../../../slices/currentUserSlice";
import {
  fetchPersonalTrainers,
  selectPersonalTrainers,
} from "../../../slices/personalTrainerSlice";
import {
  CreateUserType,
  createUser,
  fetchUser,
  fetchUsers,
  reActivateUser,
  reInviteUser,
  selectConfirmationMessage,
  selectErrorMessage,
  selectUsers,
  setConfirmationMessage,
  setErrorMessage,
  updateUser,
} from "../../../slices/userSlice";
import { InputForm } from "../../InputForm/InputForm";
import { SelectForm } from "../../SelectForm/SelectForm";
import { TextAreaForm } from "../../TextAreaForm/TextAreaForm";
import { UserImageUpload } from "../../UserImageUpload/UserImageUpload";
import "./PersonalData.css";
import { formatToFirstTwoAndLastName } from "helpers/utils";

const initialCreateUserState = {
  name: null,
  email: null,
  phone_number: null,
  birthday_date: null,
  imc: null,
  locale: null,
  fat_mass_percentage: null,
  blood_pressure_sistolic: null,
  blood_pressure_diastolic: null,
  resting_heart_beats: null,
  has_surgical_interventions: false,
  surgical_interventions: null,
  is_smoker: false,
  smoker_info: null,
  other_issues: null,
  image: null,
  weight_in_kg: null,
  height_in_cm: null,
  notes: null,
  perimeter_bicep_left: null,
  perimeter_bicep_right: null,
  perimeter_chest: null,
  perimeter_upper_leg_left: null,
  perimeter_upper_leg_right: null,
  perimeter_waist: null,
  perimeter_neck: null,
  perimeter_hip: null,
  perimeter_abdominal: null,
  visceral_fat: null,
  h2o: null,
  metabolic_age: null,
  bone_mineral_density: null,
  muscular_mass: null,
  basal_metabolic_rate: null,
  max_heart_freq: null,
  hip_waist_relation: null,
  objectives: null,
  availability: null,
  personal_trainer_id: null,
  has_cardiac_diseases: false,
  has_chest_pain_while_doing_sports: false,
  has_chest_pain_while_not_doing_sports: false,
  has_balance_loss: false,
  has_bones_issues: false,
  has_medication_for_blood_pressure: false,
  has_limitations_for_sports: false,
  cardiac_diseases: null,
  chest_pain_while_doing_sports: null,
  chest_pain_while_not_doing_sports: null,
  balance_loss: null,
  bones_issues: null,
  medication_for_blood_pressure: null,
  limitations_for_sports: null,
  practiced_sports_answer: null,
  practiced_sports: false,
  has_spine_issues: false,
  spine_issues: null,
  tourniquet_id: null,
  client_number: null,
  gender: null,
  nif: null,
  fiscal_address: null,
  fiscal_zip_code: null,
  fiscal_city: null,
};

const languages = [
  {
    value: "pt",
    label: "🇵🇹 Português",
  },
  {
    value: "en",
    label: "🇬🇧 Inglês",
  },
  {
    value: "es",
    label: "🇪🇸 Espanhol",
  },
  {
    value: "it",
    label: "🇮🇹 Italiano",
  },
  {
    value: "fr",
    label: "🇫🇷 Francês",
  },
];

type ClientExists = {
  clientExists: boolean;
};

export const PersonalData = ({ clientExists }: ClientExists) => {
  ReactGA.send("/customer-details-page");

  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const pathSegments = window.location.href.split("/");
  const userId = pathSegments[pathSegments.length - 2];
  const user = users.find((user) => user.id === userId);
  const currentUser = useSelector(selectCurrentUser);
  const [formData, setFormData] = useState<CreateUserType>({
    ...initialCreateUserState,
    identifier: clientExists
      ? user?.identifier
        ? user?.identifier
        : undefined
      : Math.max(...users.map((user) => Number(user.identifier) || 0)) + 1,
  });
  const [isNotEditing, setIsNotEditing] = useState(clientExists);
  const personalTrainers = useSelector(selectPersonalTrainers);
  const [file, setFile] = useState<File | null>(null);
  const [imageKey, setImageKey] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [usersAlreadyFetched, setUsersAlreadyFetched] = useState(false);
  const personalTrainerSelected = personalTrainers.find(
    (personalTrainer: any) =>
      personalTrainer.id === formData.personal_trainer_id
  );

  const dataToShowOnBirthdayPicker = () => {
    if (formData.birthday_date === undefined) return;
    if (formData.birthday_date === null) return;

    return `${formData.birthday_date.slice(
      0,
      4
    )}-${formData.birthday_date.slice(5, 7)}-${formData.birthday_date.slice(
      8,
      10
    )}`;
  };

  useEffect(() => {
    dispatch(fetchPersonalTrainers());
  }, [dispatch]);

  useEffect(() => {
    if (!usersAlreadyFetched && users.length === 0) {
      dispatch(fetchUsers());
      setUsersAlreadyFetched(true);
    }
  }, [dispatch, userId, clientExists, usersAlreadyFetched, users]);

  useEffect(() => {
    if (clientExists) dispatch(fetchUser(userId));
  }, [dispatch, userId, usersAlreadyFetched, clientExists]);

  useEffect(() => {
    if (clientExists && user !== undefined) {
      setFormData({
        ...user,
        personal_trainer_id: user?.personal_trainer?.id ?? null,
      });
    }
  }, [user, clientExists, userId]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "personal-data-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "personal-data-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(fetchUsers());
      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  // ERROR VARIABLES
  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);

  // VALIDATION
  const isNameValid = (): boolean => {
    if (formData?.name === null || formData.name.length === 0) {
      setNameError(nameMessage);
      return false;
    }

    setNameError(null);
    return true;
  };

  const isEmailValid = (): boolean => {
    if (formData.email?.length === 0) {
      setEmailError(emailMessage);
      return false;
    }

    if (formData.email && !validEmailRegex.test(formData.email)) {
      setEmailError(emailValidMessage);
      return false;
    }

    setEmailError(null);
    return true;
  };

  const onSubmitButtonClick = () => {
    var isValid = true;
    if (!isNameValid()) isValid = false;
    if (!isEmailValid()) isValid = false;

    if (!isValid) return;

    const image =
      file === null
        ? null
        : {
            id: imageKey!,
            storage: "cache",
            metadata: {
              size: file.size,
              mime_type: file.type,
            },
          };

    setIsNotEditing(true);

    if (clientExists)
      dispatch(
        updateUser({
          ...formData,
          id: userId,
          image: image,
          new_password: undefined,
          current_password: undefined,
        })
      );
    else {
      dispatch(
        createUser({
          ...formData,
          image: image,
        })
      );
      navigate("/dashboard/customers");
    }
  };

  return (
    <Container fluid id="personal-data">
      <Row>
        <Col>
          <div className="personal-data-flex-space-between">
            <div className="personal-data-flex-row">
              <div className="personal-data-flex-row-title">
                <h4>Dados Pessoais</h4>
                {isNotEditing && clientExists ? (
                  <Button
                    className="personal-data-edit-button"
                    onClick={() => setIsNotEditing(!isNotEditing)}
                  >
                    <BsPencilSquare />
                  </Button>
                ) : undefined}
              </div>
              {user?.status === "discarded" && (
                <GButton
                  label="Reativar cliente"
                  onClick={() => dispatch(reActivateUser(userId))}
                />
              )}
            </div>
            {user?.status === "invited" && (
              <Button
                className="personal-data-invite-user-button"
                onClick={() => dispatch(reInviteUser(user.id!))}
              >
                Reenviar convite
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Row>
          <Col className="profile-img-col">
            <UserImageUpload
              image={user?.image_medium}
              file={file}
              setFile={setFile}
              setImageKey={setImageKey}
              showEditButton={!isNotEditing}
            />
          </Col>
        </Row>
        {(currentUser?.role === "admin" || currentUser?.role === "gym") &&
          localStorage.getItem("isGymView") === "true" && (
            <>
              <Row>
                <p className="section-title">Personal Trainer</p>
              </Row>
              <Row>
                <Col lg={6}>
                  <SelectForm
                    options={personalTrainers.map((personalTrainer: any) => {
                      return {
                        id: personalTrainer.id,
                        title: formatToFirstTwoAndLastName(
                          personalTrainer.name
                        ),
                        image: personalTrainer.image_small,
                      };
                    })}
                    value={
                      personalTrainerSelected === undefined ||
                      personalTrainerSelected === null
                        ? undefined
                        : {
                            id: personalTrainerSelected.id,
                            title: formatToFirstTwoAndLastName(
                              personalTrainerSelected.name!
                            ),
                            image: personalTrainerSelected.image_small,
                          }
                    }
                    label="Utilizador"
                    onChange={(e: any) => {
                      setFormData({
                        ...formData,
                        personal_trainer_id: e.value,
                      });
                    }}
                    disabled={isNotEditing}
                    hasImage={true}
                  />
                </Col>
              </Row>
            </>
          )}
        <Row>
          <Col lg={6}>
            <p className="section-title">Número do cliente</p>
            <GInput
              value={formData?.identifier}
              type="number"
              label="Número"
              disabled={
                isNotEditing &&
                clientExists &&
                (currentUser?.role === "admin" ||
                  currentUser?.role === "gym") &&
                localStorage.getItem("isGymView") === "true"
              }
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  identifier: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={6}>
            <p className="section-title">
              Identificador do cartão do cliente (para tourniquete)
            </p>
            <GInput
              value={formData?.tourniquet_id}
              label="Identificador"
              disabled={
                isNotEditing &&
                clientExists &&
                (currentUser?.role === "admin" ||
                  currentUser?.role === "gym") &&
                localStorage.getItem("isGymView") === "true"
              }
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  tourniquet_id: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <p className="section-title">Informação Geral</p>
        </Row>
        <Col lg={6}>
          <InputForm
            value={formData?.name}
            label="Name"
            disabled={isNotEditing && clientExists}
            errorMessage={nameError}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <InputForm
            value={formData?.email}
            label="Email"
            disabled={isNotEditing && clientExists}
            errorMessage={emailError}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <InputForm
            value={formData?.phone_number}
            label="Telemóvel"
            disabled={isNotEditing && clientExists}
            type="number"
            onChange={(e: any) =>
              setFormData({
                ...formData,
                phone_number: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <InputForm
            value={dataToShowOnBirthdayPicker()}
            label="Data de nascimento"
            type="date"
            disabled={isNotEditing && clientExists}
            onChange={(e: any) => {
              if (isNaN(Date.parse(e.target.value))) return;

              const date = new Date(e.target.value);

              setFormData({
                ...formData,
                birthday_date: date.toISOString(),
              });
            }}
          />
        </Col>
        <Col lg={6}>
          <InputForm value={user?.age} label="Idade" disabled />
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Género"
            isDisabled={isNotEditing}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: "calc(3.5rem + 2px)",
                border: "1px solid #ced4da",
                borderRadius: "0.375rem",
                marginBottom: "1rem",
              }),
            }}
            value={
              formData.gender === null
                ? undefined
                : {
                    value: formData.gender,
                    label:
                      formData.gender === "male" ? "Masculino" : "Feminino",
                  }
            }
            onChange={(e: any) =>
              setFormData({
                ...formData,
                gender: e.value,
              })
            }
            options={[
              { value: "male", label: "Masculino" },
              { value: "female", label: "Feminino" },
            ]}
          />
        </Col>
        <Col lg={6}>
          <InputForm
            value={formData?.nif}
            label="NIF"
            disabled={isNotEditing && clientExists}
            type="number"
            onChange={(e: any) =>
              setFormData({
                ...formData,
                nif: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <GInput
            value={formData?.fiscal_address}
            label="Morada"
            disabled={isNotEditing && clientExists}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                fiscal_address: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <GInput
            value={formData?.fiscal_zip_code}
            label="Código Postal"
            disabled={isNotEditing && clientExists}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                fiscal_zip_code: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <GInput
            value={formData?.fiscal_city}
            label="Cidade"
            disabled={isNotEditing && clientExists}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                fiscal_city: e.target.value,
              })
            }
          />
        </Col>
        <Col lg={6}>
          <Select
            placeholder="Idioma"
            isDisabled={isNotEditing}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: "calc(3.5rem + 2px)",
                border: "1px solid #ced4da",
                borderRadius: "0.375rem",
              }),
            }}
            value={
              formData.locale === null
                ? undefined
                : {
                    value:
                      languages.find(
                        (language: any) => language.value === formData.locale
                      )?.value ?? undefined,
                    label:
                      languages.find(
                        (language: any) => language.value === formData.locale
                      )?.label ?? undefined,
                  }
            }
            onChange={(e: any) =>
              setFormData({
                ...formData,
                locale: e.value,
              })
            }
            options={languages.map((language: any) => {
              return { value: language.value, label: language.label };
            })}
          />
        </Col>
      </Row>
      <Row>
        <p className="section-title">Outras Informações</p>
      </Row>
      <Row>
        <Col>
          <TextAreaForm
            label="Objetivos"
            value={formData.objectives}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                objectives: e.target.value,
              })
            }
            disabled={isNotEditing && clientExists}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextAreaForm
            label="Disponibilidade do utilizador"
            value={formData.availability}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                availability: e.target.value,
              })
            }
            disabled={isNotEditing && clientExists}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextAreaForm
            label="Outros"
            value={formData.other_issues}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                other_issues: e.target.value,
              })
            }
            large={true}
            disabled={isNotEditing && clientExists}
          />
        </Col>
      </Row>
      {isNotEditing ? undefined : (
        <Row className="modal-flex-center padding">
          <GButton
            label="Submeter"
            className="pd-submit-btn"
            onClick={onSubmitButtonClick}
          />
        </Row>
      )}
    </Container>
  );
};
