import { BsFillXCircleFill } from "react-icons/bs";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GSection } from "components/genericComponents/GSection/GSection";
import { GInput } from "components/genericComponents/GInput/GInput";
import {
  CreateOrUpdateProductType,
  createProduct,
  updateProduct,
} from "slices/productSlice";
import { taxValues, vatExemptionReasons } from "helpers/financesInfo";

import "components/ProductsPage/ProductsSection/AddOrEditProduct/AddOrEditProduct.scss";
import { SelectForm } from "components/SelectForm/SelectForm";

type AddOrEditProductProps = {
  product?: CreateOrUpdateProductType;
  handleClose: () => void;
};

export const AddOrEditProduct = ({
  product,
  handleClose,
}: AddOrEditProductProps) => {
  const initialState = product || {
    title: "",
    purchase_price: 0,
    sale_price: 0,
    tax: taxValues.normal.value,
    tax_exemption: vatExemptionReasons[0],
    other_costs: 0,
  };
  const dispatch = useDispatch();
  const [formData, setFormData] =
    useState<CreateOrUpdateProductType>(initialState);
  const [tax, setTax] = useState<{ value: number; label: string }>(
    Object.values(taxValues).find(
      (taxValue) => taxValue.value === initialState.tax
    ) || taxValues.normal
  );
  const [exemption, setExemption] = useState<string>(
    formData.tax_exemption || vatExemptionReasons[0]
  );

  const handleSubmitButton = () => {
    if (product) dispatch(updateProduct(formData));
    else dispatch(createProduct(formData));
    handleClose();
  };

  return (
    <GSection
      label={`${product ? "Editar" : "Criar"} Produto`}
      isSpacedBetween
      renderButtons={[
        <GButton icon={<BsFillXCircleFill />} onClick={handleClose} />,
      ]}
    >
      <div className="create-product">
        <div className="create-product-div">
          <p className="title">Título</p>
          <GInput
            value={formData?.title}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                title: e.target.value as string,
              })
            }
          />
          <p className="title">Preço de custo (€)</p>
          <GInput
            value={formData?.purchase_price}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                purchase_price: e.target.value,
              })
            }
          />
          <p className="title">Preço de venda (€)</p>
          <GInput
            value={formData?.sale_price}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                sale_price: e.target.value,
              })
            }
          />
          <p className="title">IVA (%)</p>
          <SelectForm
            height="38px"
            options={Object.values(taxValues).map((taxVal) => {
              return {
                id: taxVal.value,
                title: taxVal.label,
              };
            })}
            value={{ id: tax.value, title: tax.label }}
            label=""
            onChange={(e: any) => {
              setTax({
                value: e.value,
                label: e.label.props.children[2],
              });
              setFormData({
                ...formData,
                tax: e.value,
              });
            }}
          />
          <p className="title">Isenção IVA</p>
          <SelectForm
            height="38px"
            options={vatExemptionReasons.map((exemption) => ({
              id: exemption,
              title: exemption,
            }))}
            value={{ id: exemption, title: exemption }}
            label=""
            onChange={(e: any) => {
              setExemption(e.value);
              setFormData({
                ...formData,
                tax_exemption: e.value,
              });
            }}
          />
          <p className="title">Despesas (€)</p>
          <GInput
            value={formData?.other_costs}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                other_costs: e.target.value,
              })
            }
          />
          <div className="submit-div">
            <GButton
              label="Submeter"
              onClick={handleSubmitButton}
              disabled={formData.title === ""}
            />
          </div>
        </div>
      </div>
    </GSection>
  );
};
