import { useState } from "react";

import { GModal } from "components/genericComponents/GModal/GModal";
import { AddIngredientModal } from "components/NutritionPage/AddIngredientModal/AddIngredientModal";
import { IngredientModalLogic } from "components/NutritionPage/AddIngredientModal/IngredientModalLogic/IngredientModalLogic";
import { SetWeightAndShowMacros } from "components/NutritionPage/SetWeightAndShowMacros/SetWeightAndShowMacros";
import { SearchRecipe } from "components/NutritionPage/SearchRecipe/SearchRecipe";
import { Tabs } from "components/Tabs/Tabs";
import { Food, Recipe } from "types/nutrition";

type AddMealModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  ingredients: Food[];
  sendIngredient: (weight: number, ingredient: Food) => void;
  sendRecipe: (weight: number, item: Recipe) => void;
};

export const AddMealModal = ({
  showModal,
  setShowModal,
  ingredients,
  sendIngredient,
  sendRecipe,
}: AddMealModalI) => {
  const [showAddIngredientModal, setShowAddIngredientModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showWeightModal, setShowWeightModal] = useState(false);
  const [item, setItem] = useState<Food | Recipe>();
  const [showAddRecipeModal, setShowAddRecipeModal] = useState(false);
  const [recipes] = useState<Recipe[]>([]);

  const setRecipeOrFood = (recipeOrFood: Recipe | Food) => {
    setItem(recipeOrFood);
    setShowWeightModal(true);
  };

  const sendWeight = (weight: number) => {
    if (activeTab === 0) {
      sendIngredient(weight, item! as Food);
    } else {
      sendRecipe(weight, item! as Recipe);
    }
    setShowWeightModal(false);
  };

  return (
    <div>
      <GModal showModalState={showModal} setShowModalState={setShowModal}>
        <div className="add-ingredient-modal">
          <h4>Adicionar Refeição</h4>
          <div>
            <Tabs
              tabs={["Ingredientes", "Receitas"]}
              activeIndex={activeTab}
              onTabClick={setActiveTab}
            />
          </div>
          {activeTab === 0 && !showWeightModal && (
            <IngredientModalLogic
              showModal={showAddIngredientModal}
              setShowModal={setShowAddIngredientModal}
              ingredients={ingredients}
              setIngredient={setRecipeOrFood}
            />
          )}
          {activeTab === 1 && !showWeightModal && (
            <SearchRecipe
              showModal={showAddRecipeModal}
              setShowModal={setShowAddRecipeModal}
              recipes={recipes}
              setRecipe={setRecipeOrFood}
            />
          )}
          {showWeightModal && (
            <SetWeightAndShowMacros
              item={item!}
              sendRecipe={sendWeight}
              onCancelClick={() => setShowWeightModal(false)}
            />
          )}
        </div>
      </GModal>
      <AddIngredientModal
        showModal={showAddIngredientModal}
        setShowModal={setShowAddIngredientModal}
        ingredients={ingredients}
        setIngredient={setRecipeOrFood}
      />
    </div>
  );
};
