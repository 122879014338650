import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../slices/currentUserSlice";
import { canUserEditRecipe } from "../../types/nutrition";
import { isExerciseCreatedByCurrentUser } from "../../types/exercise";
import styled from "styled-components";
import { useTable } from "react-table";
import { TbEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { BiDuplicate } from "react-icons/bi";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ReactTableRowProps, TableProps } from "../../types/table";
import "./TableComponent.css";
import { Col, Container, Row, Button } from "react-bootstrap";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border-bottom: 1px solid #eeeeee;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #eeeeee;

      :last-child {
        border-right: 0;
      }
    }
  }

  .table-no-data-col {
    text-align: center;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .table-no-data-btn {
    border-radius: 5px;
    border: 1px solid #6842ff;
    background-color: #f0ecff;
    width: 5rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6842ff;
    transition: 0.2s ease-out;
  }

  .table-no-data-btn:hover {
    background-color: #6842ff;
    color: #ffffff;
  }

  .tr-cells:hover {
    background-color: #f0ecff;
  }

  .td-cells {
    cursor: pointer;
  }

  .no-hover .td-cells {
    cursor: default;
  }

  .no-mp {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const Table: React.FC<TableProps> = ({
  name,
  columns,
  data,
  removeAction,
  addAction,
  editAction,
  duplicateAction,
  rowClickAction,
  showEditButton,
  showRemoveButton,
  showDuplicateButton,
  seeChildrenText,
  seeChildrenAction,
}) => {
  const getRowId = React.useCallback((row: any) => {
    return row?.id ?? row?.title ?? row?.user_name;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data: data,
      columns,
      getRowId,
    });

  // Render the UI for your table
  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    },
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows &&
            rows.length > 0 &&
            rows.map(
              (row: any, index: number) =>
                (prepareRow(row) as any) || (
                  <ReactTableRow
                    key={index}
                    obj={{
                      name,
                      columns,
                      data,
                      removeAction,
                      addAction,
                      editAction,
                      duplicateAction,
                      rowClickAction,
                      showEditButton,
                      showRemoveButton,
                      showDuplicateButton,
                      seeChildrenText,
                      seeChildrenAction,
                    }}
                    index={index}
                    row={row}
                    {...row.getRowProps()}
                  />
                )
            )}
        </tbody>
      </table>
    </DndProvider>
  );
};

const ReactTableRow: React.FC<ReactTableRowProps> = ({ row, obj }) => {
  const currentUser = useSelector(selectCurrentUser);
  //const [isGymView, setIsGymView] = useState(localStorage.getItem("isGymView"));

  const onEditButtonClick = (row: any) => {
    obj.editAction(row.original);
  };

  const onRowClick = (row: any, cell?: any) => {
    obj.rowClickAction(row, cell);
  };

  const onDeleteButtonClick = (row: any) => {
    obj.removeAction(row.original.id);
  };

  const onDuplicateButtonClick = (row: any) => {
    obj.duplicateAction(row.original.id);
  };

  const onSeeChildrenClick = (row: any) => {
    obj.seeChildrenAction(row.original.id);
  };

  const shouldShowRemoveButton = () => {
    if (obj.name === "exercise") {
      return (
        obj.showRemoveButton &&
        isExerciseCreatedByCurrentUser(row.values, currentUser!)
      );
    } else if (obj.name === "personal_trainers") {
      if (row.id === currentUser?.id) return false;

      return obj.showRemoveButton;
    } else if (obj.name === "customer") {
      return row.original.status !== "discarded";
    } else if (obj.name === "recipes") {
      return canUserEditRecipe(currentUser, row.original);
    } else {
      return obj.showRemoveButton;
    }
  };

  return (
    <tr {...row.getRowProps()} className="tr-cells">
      {row.cells.map((cell: any, i: any) => {
        return (
          <td
            {...cell.getCellProps()}
            onClick={() => onRowClick(row, cell)}
            className="td-cells"
            style={{ textAlign: cell.column.textAlign ?? "left" }}
          >
            <div className="td-cells-content">{cell.render("Cell")}</div>
          </td>
        );
      })}
      {obj.seeChildrenText ? (
        <td className="table-btns-td">
          <Button
            className="table-btn-show-children"
            onClick={(e: any) => onSeeChildrenClick(row)}
          >
            {obj.seeChildrenText}
          </Button>
        </td>
      ) : undefined}
      {obj.showEditButton ? (
        <>
          <td className="table-btns-td">
            <button className="table-btns">
              <TbEdit onClick={(e: any) => onEditButtonClick(row)} />
            </button>
          </td>
        </>
      ) : undefined}
      {obj.showDuplicateButton ? (
        <>
          <td className="table-btns-td">
            <button
              className="table-btns"
              onClick={(e: any) => onDuplicateButtonClick(row)}
            >
              <BiDuplicate />
            </button>
          </td>
        </>
      ) : undefined}
      <>
        <td className="table-btns-td">
          {shouldShowRemoveButton() && (
            <button
              className="table-btns"
              onClick={(e: any) => onDeleteButtonClick(row)}
            >
              <MdDeleteForever />
            </button>
          )}
        </td>
      </>
    </tr>
  );
};

export const TableComponent = (props: TableProps) => {
  return (
    <Styles>
      <div
        style={{ margin: 10, padding: "1rem" }}
        className={props.className ?? "table-border"}
      >
        {props.data.length !== 0 ? (
          <Table
            name={props.name}
            columns={props.columns}
            data={props.data}
            removeAction={props.removeAction}
            addAction={props.addAction}
            editAction={props.editAction}
            duplicateAction={props.duplicateAction}
            rowClickAction={props.rowClickAction}
            showEditButton={props.showEditButton}
            showRemoveButton={props.showRemoveButton}
            showDuplicateButton={props.showDuplicateButton}
            seeChildrenText={props.seeChildrenText}
            seeChildrenAction={props.seeChildrenAction}
          />
        ) : (
          <Container>
            <Row>
              <Col className="table-no-data-col">
                <h3>{props.textWhenNoItems}</h3>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Styles>
  );
};
