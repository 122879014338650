import { takeLatest } from "redux-saga/effects";

import {
  fetchPayments,
  fetchUserPayments,
  updateUserPayment,
  deleteUserPayment,
  fetchSaft,
  createPaymentToGoactive,
} from "slices/paymentsSlice";
import {
  fetchPaymentsSaga,
  fetchUserPaymentsSaga,
  updateUserPaymentSaga,
  deleteUserPaymentSaga,
  fetchSaftSaga,
  createPaymentToGoactiveSaga,
} from "sagas/paymentsSaga/paymentsSaga";

function* paymentsSaga() {
  yield takeLatest(fetchPayments, fetchPaymentsSaga);
  yield takeLatest(fetchUserPayments, fetchUserPaymentsSaga);
  yield takeLatest(updateUserPayment, updateUserPaymentSaga);
  yield takeLatest(deleteUserPayment, deleteUserPaymentSaga);
  yield takeLatest(fetchSaft, fetchSaftSaga);
  yield takeLatest(createPaymentToGoactive, createPaymentToGoactiveSaga);
}

export default paymentsSaga;
