import { combineReducers } from "redux";

import userSlice from "slices/userSlice";
import currentUserSlice from "slices/currentUserSlice";
import workoutSlice from "slices/workoutSlice";
import exerciseSlice from "slices/exerciseSlice";
import waitlistSlice from "slices/waitlistSlice";
import courseSlice from "slices/courseSlice";
import courseScheduleSlice from "slices/courseScheduleSlice";
import workoutScheduleSlice from "slices/workoutScheduleSlice";
import notificationSlice from "slices/notificationSlice";
import chatgptSlice from "slices/chatgptSlice";
import chatSlice from "slices/chatSlice";
import personalTrainerSlice from "slices/personalTrainerSlice";
import exerciseCategorySlice from "slices/exerciseCategorySlice";
import gymRoomSlice from "slices/gymRoomSlice";
import paymentsPackagesSlice from "slices/paymentsPackagesSlice";
import paymentsSlice from "slices/paymentsSlice";
import workoutHistorySlice from "slices/workoutHistorySlice";
import insuranceSlice from "slices/insuranceSlice";
import productSlice from "slices/productSlice";
import productTransactionSlice from "slices/productTransactionSlice";
import crmSlice from "slices/crmSlice";
import evaluationsSlice from "slices/evaluationsSlice";
import nutritionSlice from "slices/nutritionSlice";
import nutritionSchedulerSlice from "slices/nutritionSchedulerSlice";
import companyPlanSlice from "slices/companyPlanSlice";
import dietSlice from "slices/dietSlice";

const rootReducer = combineReducers({
  userSlice,
  currentUserSlice,
  workoutSlice,
  exerciseSlice,
  waitlistSlice,
  courseSlice,
  courseScheduleSlice,
  workoutScheduleSlice,
  notificationSlice,
  chatgptSlice,
  chatSlice,
  personalTrainerSlice,
  exerciseCategorySlice,
  gymRoomSlice,
  paymentsPackagesSlice,
  paymentsSlice,
  workoutHistorySlice,
  insuranceSlice,
  productSlice,
  productTransactionSlice,
  crmSlice,
  evaluationsSlice,
  nutritionSlice,
  nutritionSchedulerSlice,
  companyPlanSlice,
  dietSlice
});

export default rootReducer;
