import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GCard } from "components/genericComponents/GCard/GCard";
import {
  fetchRevenue,
  fetchUserCounters,
  fetchUsers,
  selectRevenue,
  selectUserCounters,
  selectUsers,
} from "../../../slices/userSlice";
import {
  fetchProductRevenue,
  selectProductRevenue,
} from "../../../slices/productSlice";
import { User, UserCounter } from "../../../types/user";

import "components/DashboardHomePage/UserChart/UserChart.scss";
import { addDays, endOfMonth, format, startOfMonth } from "date-fns";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { DateRange } from "react-day-picker";
import { isMonthDayInRange } from "helpers/date";
import { formatToFirstTwoAndLastName } from "helpers/utils";

export const UserChart = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const counters = useSelector(selectUserCounters);
  const revenueFromClients = useSelector(selectRevenue);
  const revenueFromProducts = useSelector(selectProductRevenue);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const [dateInterval, setDateInterval] = useState<DateRange>({
    from: startOfMonth(new Date()),
    to: new Date(),
  });
  const [birthdaysInterval, setBirthdaysInterval] = useState<DateRange>({
    from: new Date(),
    to: addDays(new Date(), 7),
  });
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const personalTrainersCount =
    (counters?.find(
      (counter: UserCounter) => counter.role === "personal_trainer"
    )?.count ?? 0) +
    (counters?.find((counter: UserCounter) => counter.role === "admin")
      ?.count ?? 0);

  useEffect(() => {
    if (users.length === 0 && !alreadyFetched) {
      dispatch(fetchUsers());
      setAlreadyFetched(true);
    }
  }, [dispatch, users, alreadyFetched]);

  useEffect(() => {
    dispatch(fetchUserCounters());

    if (isGymView && dateInterval.from && dateInterval.to) {
      const startDate = startOfMonth(dateInterval.from).toLocaleDateString();
      const endDate = endOfMonth(dateInterval.to).toLocaleDateString();

      dispatch(fetchRevenue({ start_at: startDate, end_at: endDate }));
      dispatch(fetchProductRevenue({ start_at: startDate, end_at: endDate }));
    }
  }, [dispatch, dateInterval, isGymView]);

  const handleSetDateInterval = (dateInterval: DateRange) => {
    setDateInterval(dateInterval);
  };

  const handleSetBirthdaysInterval = (dateInterval: DateRange) => {
    setBirthdaysInterval(dateInterval);
  };

  return (
    <div id="user-chart">
      {isGymView && (
        <GCard
          description="Personal Trainers"
          value={personalTrainersCount}
          isSquared
        />
      )}
      <GCard
        description="Clientes"
        value={
          counters?.find((counter: UserCounter) => counter.role === "user")
            ?.count ?? 0
        }
        isSquared
      />
      <GCard>
        <p className="birthdays-title">Aniversários</p>
        <GDatePicker
          mode="range"
          setDateInterval={handleSetBirthdaysInterval}
          currDateInterval={birthdaysInterval}
          className="date-picker"
        />
        <div className="birthdays-container">
          {users.map((user: User) => {
            if (
              user.birthday_date === undefined ||
              user.birthday_date === null ||
              birthdaysInterval.from === undefined ||
              birthdaysInterval.to === undefined ||
              (birthdaysInterval.from !== undefined &&
                birthdaysInterval.to !== undefined &&
                !isMonthDayInRange(
                  new Date(user.birthday_date),
                  birthdaysInterval.from,
                  birthdaysInterval.to
                ))
            )
              return null;
            return (
              <div className="birthdays-div">
                <div className="birthdays-img-div">
                  <img
                    src={
                      user.image_small !== null
                        ? user.image_small
                        : "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                    }
                    alt="user"
                  />
                </div>
                <div>
                  <p className="birthdays-name">
                    {formatToFirstTwoAndLastName(user.name)}
                  </p>
                  {user.birthday_date !== undefined && (
                    <p className="birthdays-date">
                      {format(new Date(user.birthday_date!), "EEEE, dd MMMM")}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </GCard>
      {isGymView && (
        <GCard>
          <GDatePicker
            mode="range"
            setDateInterval={handleSetDateInterval}
            currDateInterval={dateInterval}
            disabledDays={{ after: new Date() }}
            className="date-picker"
          />
          <div className="revenue-div">
            <GCard
              description="Receita Clientes"
              variant="transparent"
              value={Number.parseFloat(revenueFromClients.toFixed(2))}
              isValuePrice
            />
            <GCard
              description="Receita Produtos"
              variant="transparent"
              value={Number.parseFloat(
                (revenueFromProducts?.revenue ?? 0).toFixed(2)
              )}
              isValuePrice
            />
            <GCard
              description="Lucro Produtos"
              variant="transparent"
              value={Number.parseFloat(
                (revenueFromProducts?.profit ?? 0).toFixed(2)
              )}
              isValuePrice
            />
          </div>
        </GCard>
      )}
    </div>
  );
};
