import { useEffect, useRef } from "react";

export const formatToFirstTwoAndLastName = (
  fullName: string | undefined | null
): string => {
  if (!fullName) return "";
  const parts = fullName.trim().split(" ");
  if (parts.length > 2) {
    return `${parts[0]} ${parts[1]} ${parts[parts.length - 1]}`;
  }
  return fullName;
};

export const getYoutubeIdFromUrl = (url: string): string => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);

  if (match && match[7].length === 11) return match[7];
  else return "";
};

export const debounce = <T>(
  func: (this: T, ...args: any[]) => any,
  duration: number
) => {
  let timeout: any;

  return function (this: T, ...args: any[]) {
    const effect = () => {
      timeout = null;
      return func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(effect, duration);
  };
};

export function useUnmount(func: () => void) {
  const funcRef = useRef(func)

  funcRef.current = func

  useEffect(
    () => () => {
      funcRef.current()
    },
    [],
  )
}