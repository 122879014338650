import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  CourseSchedule,
  CourseScheduleCounter,
  UserCourseScheduleCounter,
} from "../types/courseSchedule";
import {
  courseScheduleSuccessfullyCreated,
  courseScheduleSuccessfullyUpdated,
  courseScheduleSuccessfullyDeleted,
} from "../helpers/messages";

export type CreateCourseScheduleType = {
  slots_total: number | undefined;
  level: string | undefined;
  start_at: string;
  end_at: string;
  course_id: string | undefined;
  user_ids: Array<string>;
  title: string;
  company_room_id: string | undefined;
  recurrence?: Recurrence;
  other_participants_count: number;
};

type Weekdays =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type DeleteCourseSchedules = {
  id: string;
  delete_rule: boolean;
};

export type Recurrence = {
  week_days: Weekdays[];
  week_recurrence: number;
};

export type UpdateCourseScheduleType = {
  id: string;
  slots_total: number | undefined;
  level: string | undefined;
  start_at: string;
  end_at: string;
  course_id: string | undefined;
  user_ids: Array<string>;
  title: string;
  company_room_id: string | undefined;
  recurrence?: Recurrence;
  other_participants_count: number;
};

export type FetchCourseSchedulesType = {
  room_id?: string;
  course_id?: string;
  include_my_schedules: boolean;
  include_company_schedules: boolean;
  personal_trainer_id?: string;
  from?: string;
  to?: string;
};

export type FetchCourseScheduleCountersType = {
  start_at: string;
  end_at: string;
};

type CourseScheduleState = {
  coursesSchedules: Array<CourseSchedule>;
  courseScheduleCounters: Array<CourseScheduleCounter>;
  userCourseScheduleCounters: Array<UserCourseScheduleCounter>;
  isLoading: boolean;
  error: string | undefined;
  confirmationMessage: string | undefined;
};

const initialState: CourseScheduleState = {
  coursesSchedules: [],
  courseScheduleCounters: [],
  userCourseScheduleCounters: [],
  isLoading: false,
  error: undefined,
  confirmationMessage: undefined,
};

const courseScheduleSlice = createSlice({
  name: "courseSchedule",
  initialState: initialState,
  reducers: {
    fetchCoursesSchedules(
      state: CourseScheduleState,
      action: PayloadAction<FetchCourseSchedulesType>
    ) {
      state.isLoading = true;
    },
    fetchCoursesSchedulesSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<Array<CourseSchedule>>
    ) {
      state.coursesSchedules = action.payload;
      state.isLoading = false;
    },
    fetchCourseSchedule(
      state: CourseScheduleState,
      action: PayloadAction<string>
    ) {},
    fetchCourseScheduleSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<CourseSchedule>
    ) {
      if (state.coursesSchedules.length === 0) {
        state.coursesSchedules = [action.payload];
      } else {
        state.coursesSchedules = state.coursesSchedules.map(
          (schedule: CourseSchedule) => {
            if (schedule.id === action.payload.id) return action.payload;
            else return schedule;
          }
        );
      }
    },
    fetchCourseScheduleCounters(
      state: CourseScheduleState,
      action: PayloadAction<FetchCourseScheduleCountersType>
    ) {},
    fetchCourseScheduleCountersSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<Array<CourseScheduleCounter>>
    ) {
      state.courseScheduleCounters = action.payload;
    },
    fetchUserCourseScheduleCounters(
      state: CourseScheduleState,
      action: PayloadAction<FetchCourseScheduleCountersType>
    ) {},
    fetchUserCourseScheduleCountersSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<Array<UserCourseScheduleCounter>>
    ) {
      state.userCourseScheduleCounters = action.payload;
    },
    createCourseSchedule(
      state: CourseScheduleState,
      action: PayloadAction<CreateCourseScheduleType>
    ) {},
    createCourseScheduleSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<Array<CourseSchedule>>
    ) {
      return {
        ...state,
        confirmationMessage: courseScheduleSuccessfullyCreated,
        coursesSchedules: state.coursesSchedules.concat(action.payload),
      };
    },
    updateCourseSchedule(
      state: CourseScheduleState,
      action: PayloadAction<UpdateCourseScheduleType>
    ) {},
    updateCourseScheduleSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<CourseSchedule>
    ) {
      const index = state.coursesSchedules.findIndex(
        (courseSchedule: CourseSchedule) =>
          courseSchedule.id === action.payload.id
      );

      state.coursesSchedules[index] = action.payload;
      state.confirmationMessage = courseScheduleSuccessfullyUpdated;
    },
    deleteCourseSchedule(
      state: CourseScheduleState,
      action: PayloadAction<DeleteCourseSchedules>
    ) {
      state.isLoading = true;
    },
    deleteCourseScheduleSucceeded(
      state: CourseScheduleState,
      action: PayloadAction<string>
    ) {
      state.coursesSchedules = [...state.coursesSchedules].filter(
        (schedule) => schedule.id !== action.payload
      );
      state.confirmationMessage = courseScheduleSuccessfullyDeleted;
      state.isLoading = false;
    },
    setErrorMessage(
      state: CourseScheduleState,
      action: PayloadAction<string | undefined>
    ) {
      state.error = action.payload;
    },
    setConfirmationMessage(
      state: CourseScheduleState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
    cleanErrorMessage(state: CourseScheduleState) {
      state.error = undefined;
    },
  },
});

export const {
  fetchCoursesSchedules,
  fetchCoursesSchedulesSucceeded,
  createCourseSchedule,
  createCourseScheduleSucceeded,
  updateCourseSchedule,
  updateCourseScheduleSucceeded,
  deleteCourseSchedule,
  deleteCourseScheduleSucceeded,
  setErrorMessage,
  setConfirmationMessage,
  cleanErrorMessage,
  fetchCourseSchedule,
  fetchCourseScheduleSucceeded,
  fetchCourseScheduleCounters,
  fetchCourseScheduleCountersSucceeded,
  fetchUserCourseScheduleCounters,
  fetchUserCourseScheduleCountersSucceeded,
} = courseScheduleSlice.actions;

export const selectCoursesSchedules = (state: RootState) =>
  state.courseScheduleSlice.coursesSchedules;
export const selectCourseScheduleCounters = (state: RootState) =>
  state.courseScheduleSlice.courseScheduleCounters;
export const selectUserCourseScheduleCounters = (state: RootState) =>
  state.courseScheduleSlice.userCourseScheduleCounters;
export const selectIsLoading = (state: RootState) =>
  state.courseScheduleSlice.isLoading;
export const selectErrorMessage = (state: RootState) =>
  state.courseScheduleSlice.error;
export const selectConfirmationMessage = (state: RootState) =>
  state.courseScheduleSlice.confirmationMessage;

export default courseScheduleSlice.reducer;
