import { axiosRequest, errorMessage } from "./request";
import { Diet } from "../types/diet";
import {
  CreateOrUpdateDietType,
} from "../slices/dietSlice";

export type DietResponseType = {
  succeeded: boolean;
  diets: Array<Diet> | undefined
  diet: Diet | undefined;
  errorMessage: string | undefined;
};

export const fetchDietsRequest = async (): Promise<DietResponseType> => {
  try {
    const response = await axiosRequest().get("diets")
    console.log(response.data)
    const diets = response.data as Array<Diet>

    return {
      succeeded: true,
      diets: diets,
    } as DietResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as DietResponseType;
  }
};

export const fetchDietRequest = async (id: string): Promise<DietResponseType> => {
  try {
    const response = await axiosRequest().get(`diets/${id}`)
    const diet = response.data as Diet

    return {
      succeeded: true,
      diet: diet,
    } as DietResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as DietResponseType;
  }
};

export const createDietRequest = async (params: CreateOrUpdateDietType): Promise<DietResponseType> => {
  try {
    const response = await axiosRequest().post(`diets`, { diet: params })
    const diet = response.data as Diet

    return {
      succeeded: true,
      diet: diet,
    } as DietResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as DietResponseType;
  }
};

export const updateDietRequest = async (params: CreateOrUpdateDietType): Promise<DietResponseType> => {
  try {
    const response = await axiosRequest().put(`diets/${params.id}`, { diet: params })
    const diet = response.data as Diet

    return {
      succeeded: true,
      diet: diet,
    } as DietResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as DietResponseType;
  }
};

export const deleteDietRequest = async (id: string): Promise<DietResponseType> => {
  try {
    await axiosRequest().delete(`diets/${id}`)

    return {
      succeeded: true,
    } as DietResponseType;
  } catch (e) {
    return {
      succeeded: false,
      errorMessage: errorMessage(e),
    } as DietResponseType;
  }
};
