import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiFillMessage } from "react-icons/ai";
import {
  BsCalendarEventFill,
  BsFillHouseFill,
  BsFillPersonLinesFill,
  BsGearFill,
  BsJournalText,
  BsPersonBadgeFill,
} from "react-icons/bs";
import { FaAppleAlt, FaRunning, FaSignOutAlt } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FaAnglesUp, FaCartShopping, FaReceipt } from "react-icons/fa6";
import { Link } from "react-router-dom";

import {
  fetchCurrentUser,
  logout,
  selectCurrentUser,
} from "slices/currentUserSlice";
import { Chat } from "types/chat";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { selectChats } from "slices/chatSlice";

import "App.scss";
import logo from "assets/logo.svg";
import "components/Sidebar/Sidebar.css";
import { GButton } from "components/genericComponents/GButton/GButton";
import { UpdateAccountModal } from "components/shared/UpdateAccountModal/UpdateAccountModal";
import { isOwner } from "helpers/validation";

type SidebarProps = {
  activePage: string;
  setActivePage: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const Sidebar = (props: SidebarProps) => {
  const [cookies, , removeCookie] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentPath = useLocation();
  const navigate = useNavigate();
  const chats = useSelector(selectChats);
  const [showModal, setShowModal] = useState(false);
  const [isGymView, setIsGymView] = useState(localStorage.getItem("isGymView"));
  const numberOfChatsWithMessagesToRead = chats.filter((chat: Chat) => {
    return chat.messages_to_read_count > 0;
  }).length;
  const canHaveGymView =
    currentUser?.company?.is_gym && currentUser?.role === "admin";

  useEffect(() => {
    if (currentUser === undefined && currentUserCookies?.id !== undefined) {
      dispatch(fetchCurrentUser(currentUserCookies?.id));
    }
  }, [currentUser, dispatch, currentUserCookies]);

  const dispatchLogout = async () => {
    removeCookie("current_user", { path: "/" });
    await dispatch(logout({ email: currentUser?.email! }));
    localStorage.removeItem("token");
    localStorage.removeItem("isGymView");
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      window.location.replace("http://localhost:3000");
    } else {
      window.location.replace("https://goactivedash.com");
    }
  };

  const handleUpdatePlan = () => {
    setShowModal(true);
  };

  return (
    <div id="sidebar" className={!props.isOpen ? "is-closed" : ""}>
      {currentUser === undefined && currentUserCookies?.id === undefined ? (
        <Navigate to="/" replace={true} />
      ) : (
        <Container className="sidebar-container" fluid>
          <Row>
            <Col
              className={`sidebar-arrow-col${
                !props.isOpen ? " is-closed" : ""
              }`}
            >
              <MdKeyboardArrowRight
                className={`sidebar-arrow ${props.isOpen ? "is-closed" : ""}`}
                onClick={() => props.setIsOpen(!props.isOpen)}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className={`sidebar-logo-col ${!props.isOpen ? "is-closed" : ""}`}
            >
              <img
                src={logo}
                alt="logo"
                className={!props.isOpen ? "sidebar-image-small" : ""}
              />
              {props.isOpen ? (
                <p className="sidebar-logo-text">
                  {process.env.REACT_APP_APP_NAME}
                </p>
              ) : undefined}
            </Col>
          </Row>
          <div className="sidebar-items-div">
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"home"}
                onClick={() => props.setActivePage("HomeDashboard")}
                className={
                  currentPath.pathname === "/dashboard/home"
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <BsFillHouseFill />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Início</p>
                ) : undefined}
              </Link>
            </Row>
            {isGymView === "true" ? (
              <Row
                className={`sidebar-item-row ${
                  !props.isOpen ? "is-closed" : ""
                }`}
              >
                <Link
                  to={"personal_trainers"}
                  onClick={() => props.setActivePage("PersonalTrainers")}
                  className={
                    currentPath.pathname.includes("dashboard/personal_trainers")
                      ? "sidebar-links-active sidebar-item-div"
                      : "sidebar-links sidebar-item-div"
                  }
                >
                  <BsPersonBadgeFill />
                  {props.isOpen ? (
                    <p className="sidebar-links-p">Personal Trainers</p>
                  ) : undefined}
                </Link>
              </Row>
            ) : undefined}
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"customers"}
                onClick={() => props.setActivePage("Customers")}
                className={
                  currentPath.pathname.includes("dashboard/customers")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <BsFillPersonLinesFill />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Clientes</p>
                ) : undefined}
              </Link>
            </Row>
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"workouts"}
                onClick={() => props.setActivePage("Workouts")}
                className={
                  currentPath.pathname.includes("dashboard/workouts")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <BsJournalText />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Planos de Treino</p>
                ) : undefined}
              </Link>
            </Row>
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"exercises"}
                onClick={() => {
                  props.setActivePage("Exercises");
                }}
                className={
                  currentPath.pathname.includes("dashboard/exercises")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <FaRunning />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Exercícios</p>
                ) : undefined}
              </Link>
            </Row>
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"calendar"}
                onClick={() => {
                  props.setActivePage("Courses");
                }}
                className={
                  currentPath.pathname.includes("dashboard/calendar")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <BsCalendarEventFill />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Calendário</p>
                ) : undefined}
              </Link>
            </Row>
            {isGymView === "true" ? (
              <Row
                className={`sidebar-item-row ${
                  !props.isOpen ? "is-closed" : ""
                }`}
              >
                <Link
                  to={"products"}
                  onClick={() => props.setActivePage("Products")}
                  className={
                    currentPath.pathname.includes("dashboard/products")
                      ? "sidebar-links-active sidebar-item-div"
                      : "sidebar-links sidebar-item-div"
                  }
                >
                  <FaCartShopping />
                  {props.isOpen ? (
                    <p className="sidebar-links-p">Produtos</p>
                  ) : undefined}
                </Link>
              </Row>
            ) : undefined}
            {/*{isGymView === "true" ? (
              <Row
                className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
              >
                <Link
                  to={"tablet"}
                  onClick={() => props.setActivePage("TabletPage")}
                  className={
                    currentPath.pathname.includes("dashboard/tablet")
                      ? "sidebar-links-active sidebar-item-div"
                      : "sidebar-links sidebar-item-div"
                  }
                >
                  <FaTablet />
                  {props.isOpen ? (
                    <p className="sidebar-links-p">Modo Controlo de entradas</p>
                  ) : undefined}
                </Link>
              </Row>
            ) : undefined} */}
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <Link
                to={"nutrition/diets"}
                onClick={() => {
                  props.setActivePage("Nutrition");
                }}
                className={
                  currentPath.pathname.includes("dashboard/nutrition")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <FaAppleAlt />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Nutrição</p>
                ) : undefined}
              </Link>
            </Row>
            {currentUser && isOwner(currentUser) && (
              <Row
                className={`sidebar-item-row ${
                  !props.isOpen ? "is-closed" : ""
                }`}
              >
                <Link
                  to={"billing"}
                  onClick={() => {
                    props.setActivePage("Billing");
                  }}
                  className={
                    currentPath.pathname.includes("dashboard/billing")
                      ? "sidebar-links-active sidebar-item-div"
                      : "sidebar-links sidebar-item-div"
                  }
                >
                  <FaReceipt />
                  {props.isOpen ? (
                    <p className="sidebar-links-p">Faturação</p>
                  ) : undefined}
                </Link>
              </Row>
            )}
            <Row
              className={`sidebar-item-row ${
                !props.isOpen ? "is-closed" : ""
              } chat-icon`}
            >
              <Link
                to={"chats"}
                onClick={() => {
                  props.setActivePage("Chats");
                }}
                className={
                  currentPath.pathname.includes("dashboard/chats")
                    ? "sidebar-links-active sidebar-item-div"
                    : "sidebar-links sidebar-item-div"
                }
              >
                <AiFillMessage />
                {props.isOpen ? (
                  <p className="sidebar-links-p">Chats</p>
                ) : undefined}
              </Link>
              {numberOfChatsWithMessagesToRead > 0 && (
                <p className="sidebar-messages-count">
                  {numberOfChatsWithMessagesToRead}
                </p>
              )}
            </Row>
          </div>
          <div className="app-space-filler" />
          <div className="sidebar-divider no-margin" />
          {canHaveGymView && !props.isOpen && (
            <Row
              className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
            >
              <div
                className="sidebar-upgrade-button-div"
                onClick={handleUpdatePlan}
              >
                <div className="sidebar-upgrade-button">
                  <FaAnglesUp />
                </div>
              </div>
            </Row>
          )}
          {canHaveGymView ? (
            <Row
              className={`sidebar-item-row sidebar-account-type-row ${
                !props.isOpen ? "is-closed" : ""
              }`}
            >
              {props.isOpen ? (
                <div className="sidebar-account-type-div">
                  <Form.Check // prettier-ignore
                    checked={isGymView === "true"}
                    type="switch"
                    className="purple-switch sidebar-account-type-switch props-open"
                    onChange={() => {
                      setIsGymView(isGymView === "true" ? "false" : "true");
                      localStorage.setItem(
                        "isGymView",
                        isGymView === "true" ? "false" : "true"
                      );
                      navigate("/dashboard/home");
                      navigate(0);
                    }}
                  />
                  {isGymView === "true" ? (
                    <p className="sidebar-account-type-text">Gym</p>
                  ) : (
                    <p className="sidebar-account-type-text">PT</p>
                  )}
                </div>
              ) : (
                <>
                  {isGymView === "true" ? (
                    <p className="sidebar-account-type-text">Gym</p>
                  ) : (
                    <p className="sidebar-account-type-text">PT</p>
                  )}
                  <Form.Check // prettier-ignore
                    checked={isGymView === "true"}
                    type="switch"
                    className="purple-switch sidebar-account-type-switch"
                    onChange={() => {
                      setIsGymView(isGymView === "true" ? "false" : "true");
                      localStorage.setItem(
                        "isGymView",
                        isGymView === "true" ? "false" : "true"
                      );
                      navigate("/dashboard/home");
                      navigate(0);
                    }}
                  />
                </>
              )}
            </Row>
          ) : undefined}
          <Row
            className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
          >
            <Link
              to={"settings/account"}
              onClick={() => {
                props.setActivePage("Settings");
              }}
              className={
                currentPath.pathname.includes("dashboard/settings")
                  ? "sidebar-links-active sidebar-item-div"
                  : "sidebar-links sidebar-item-div"
              }
            >
              <BsGearFill />
              {props.isOpen ? (
                <p className="sidebar-links-p">Definições</p>
              ) : undefined}
            </Link>
          </Row>
          <Row
            className={`sidebar-item-row ${!props.isOpen ? "is-closed" : ""}`}
          >
            <div
              onClick={dispatchLogout}
              className="sidebar-item-div logout-btn"
            >
              <FaSignOutAlt />
              {props.isOpen ? (
                <p className="sidebar-links-p">Sair</p>
              ) : undefined}
            </div>
          </Row>
          <div className="sidebar-divider" />
          <Row>
            <Col className="sidebar-user-col">
              <Container fluid className="sidebar-users-container">
                <Row
                  className={`sidebar-users-row ${
                    !props.isOpen ? "is-closed" : ""
                  }`}
                >
                  <Col className="sidebar-user-inner-col">
                    <img
                      className="sidebar-user-avatar"
                      src={
                        (localStorage.getItem("isGymView") === "true"
                          ? currentUser?.company?.image_small
                          : currentUser?.image_small) ??
                        "https://gym-app-bucket.s3.eu-west-2.amazonaws.com/gym-logo.png"
                      }
                      alt="avatar"
                    />
                  </Col>
                  {props.isOpen ? (
                    <Col className="sidebar-user-inner-col-1">
                      <p className="sidebar-users-name">
                        {localStorage.getItem("isGymView") === "true"
                          ? currentUser?.company?.name
                          : formatToFirstTwoAndLastName(currentUser?.name)}
                      </p>
                      {currentUser?.company?.plan !== undefined ? (
                        <p className="sidebar-users-plan">
                          {currentUser?.company?.plan?.title}
                        </p>
                      ) : undefined}
                      {currentUser && isOwner(currentUser) && (
                        <GButton
                          className="sidebar-users-upgrade-plan-button"
                          label="Atualizar plano"
                          onClick={handleUpdatePlan}
                        />
                      )}
                    </Col>
                  ) : undefined}
                </Row>
              </Container>
            </Col>
          </Row>
          <UpdateAccountModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Container>
      )}
    </div>
  );
};
