import { useDispatch, useSelector } from "react-redux";
import { DashboardTitle } from "../DashboardTitle/DashboardTitle";
import React, { useEffect, useState } from "react";
import {
  fetchCurrentUser,
  selectCurrentUser,
} from "../../slices/currentUserSlice";
import { CoursesChart } from "./CoursesChart/CoursesChart";
import { UserSchedulesChart } from "./UserSchedulesChart/UserSchedulesChart";
import { UserChart } from "./UserChart/UserChart";
import { UsersWithMissingPayments } from "./UsersWithMissingPayments/UsersWithMissingPayments";

import "./DashboardHomePage.css";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { Col, Row, Container } from "react-bootstrap";
import { formatToFirstTwoAndLastName } from "helpers/utils";

export const DashboardHomePage = () => {
  ReactGA.send("/home");

  const [cookies] = useCookies(["current_user"]);
  const currentUserCookies = cookies.current_user;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const [isFirstPageOpen, setIsFirstPageOpen] = useState(true);

  //const notifications = useSelector(selectNotifications);

  //const NotificationCard = (notification: Notification) => {
  //  return (
  //    <div className="home-page-notification-card">
  //      <div className="home-page-flex-row-space-between">
  //        <div className="home-page-flex-row">
  //          <div className="home-page-notificatin-card-image-div">
  //            <img
  //              className="home-page-notification-card-image"
  //              src={notification.from.image_small}
  //              alt=""
  //            />
  //          </div>
  //          <div className="home-page-flex-col">
  //            <p className="home-page-notification-card-title">
  //              {" "}
  //              {notification.title_portuguese}
  //            </p>
  //            <p className="home-page-notification-card-message">
  //              {" "}
  //              {notification.message_portuguese}
  //            </p>
  //          </div>
  //        </div>
  //        <div
  //          className="home-page-delete-notification-icon"
  //          onClick={() => dispatch(deleteNotification(notification.id))}
  //        >
  //          <MdDeleteForever />
  //        </div>
  //      </div>
  //    </div>
  //  );
  //};

  useEffect(() => {
    if (currentUser === undefined && currentUserCookies?.id !== undefined) {
      dispatch(fetchCurrentUser(currentUserCookies?.id));
    }
  }, [currentUser, dispatch, currentUserCookies]);

  //useEffect(() => {
  //  dispatch(fetchNotifications());
  //}, [dispatch]);
  //

  return (
    <Container fluid id="home-page">
      <DashboardTitle
        doNotShowBackButton={true}
        title={`Olá, ${
          !isGymView
            ? formatToFirstTwoAndLastName(currentUser?.name)
            : currentUser?.company?.name
        } 👋`}
        showAddButton={false}
        infoLink="jvkFH-W3Fi8"
      />
      <Row className="home-page-row">
        <Col className="home-page-col">
          <p
            onClick={() => setIsFirstPageOpen(true)}
            className={`home-page-col-text ${isFirstPageOpen ? "active" : ""}`}
          >
            Geral
          </p>
          <p
            onClick={() => setIsFirstPageOpen(false)}
            className={`home-page-col-text ${!isFirstPageOpen ? "active" : ""}`}
          >
            Outros
          </p>
        </Col>
      </Row>
      <div className="home-page-row">
        <div className="first-page-div">
          {isFirstPageOpen && <UserChart />}
          {isFirstPageOpen && <UsersWithMissingPayments />}
        </div>
        {!isFirstPageOpen && isGymView && <CoursesChart />}
        {!isFirstPageOpen && <UserSchedulesChart />}
      </div>
      {
        // TODO: Might have notifications later
        //<Container fluid className="notifications-container">
        //  {notifications.length === 0 ? (
        //    <h3 className="notifications-title">Não tem notificações hoje</h3>
        //  ) : (
        //    <>
        //      { localStorage.getItem('isGymView') === 'false' ?
        //        notifications.map((notification: Notification) =>
        //          NotificationCard(notification)) :
        //        <h3 className="notifications-title">Não tem notificações hoje</h3>
        //      }
        //    </>
        //  )}
        //</Container>
      }
    </Container>
  );
};
