import { useState } from "react";
import { DateRange, DayPicker, Matcher } from "react-day-picker";
import { pt } from "date-fns/locale";

import { GButton } from "components/genericComponents/GButton/GButton";
import { getYearAndMonthAndDay } from "helpers/date";
import { compareAsc } from "date-fns";

import "components/genericComponents/GDatePicker/GDatePicker.scss";
import { MdClose } from "react-icons/md";

type GDatePickerProps = {
  currDate?: Date;
  setCurrDate?: (val: Date | undefined) => void;
  disabled?: boolean;
  className?: string;
  disabledDays?: Matcher;
  mode?: "single" | "range";
  setDateInterval?: (val: DateRange) => void;
  currDateInterval?: DateRange;
  clearable?: boolean;
};

export const GDatePicker = ({
  currDate,
  setCurrDate,
  disabled,
  className,
  disabledDays,
  mode = "single",
  setDateInterval,
  currDateInterval,
  clearable,
}: GDatePickerProps) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const handleResetClick = () => {
    if (setDateInterval !== undefined)
      setDateInterval({ from: undefined, to: undefined });
  };

  const isSelectingFirstDay = (
    from: Date | undefined,
    to: Date | undefined,
    day: Date
  ) => {
    const isBeforeFirstDay = from && compareAsc(day, from) < 0;
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  return (
    <div className={className}>
      <GButton
        disabled={disabled}
        variant="transparent"
        onClick={() => setShowDatePicker(!showDatePicker)}
      >
        {currDate !== undefined
          ? getYearAndMonthAndDay(currDate)
          : currDateInterval?.from !== undefined ||
            currDateInterval?.to !== undefined
          ? `${
              currDateInterval.from
                ? getYearAndMonthAndDay(currDateInterval.from)
                : ""
            } - ${
              currDateInterval.to
                ? getYearAndMonthAndDay(currDateInterval.to)
                : ""
            }`
          : "Selecionar datas"}
        {clearable &&
          (currDate !== undefined ||
            currDateInterval?.from !== undefined ||
            currDateInterval?.to !== undefined) && (
            <GButton
              className="clear-button"
              variant="transparent"
              onClick={() => {
                if (setCurrDate !== undefined) setCurrDate(undefined);
                if (setDateInterval !== undefined)
                  setDateInterval({ from: undefined, to: undefined });
              }}
            >
              <MdClose />
            </GButton>
          )}
      </GButton>
      {showDatePicker && (
        <div className="course-schedule-modal-calendar-div">
          {mode === "single" ? (
            <DayPicker
              mode="single"
              selected={currDate}
              locale={pt}
              disabled={disabledDays}
              onSelect={(e: Date | undefined): void => {
                if (e === undefined) return;

                const day = (e as Date).getDate();
                const month = (e as Date).getMonth();
                const year = (e as Date).getFullYear();
                let tempCurrDate = new Date();

                tempCurrDate.setDate(day);
                tempCurrDate.setMonth(month);
                tempCurrDate.setFullYear(year);

                if (setCurrDate !== undefined) setCurrDate(tempCurrDate);
                setShowDatePicker(false);
              }}
            />
          ) : (
            <DayPicker
              className="range-date-picker"
              mode="range"
              selected={currDateInterval}
              locale={pt}
              disabled={disabledDays}
              onDayClick={(day) => {
                if (
                  currDateInterval &&
                  currDateInterval.from &&
                  currDateInterval.to &&
                  day >= currDateInterval.from &&
                  day <= currDateInterval.to
                ) {
                  handleResetClick();
                  return;
                }
                if (
                  isSelectingFirstDay(
                    currDateInterval?.from,
                    currDateInterval?.to,
                    day
                  )
                ) {
                  if (setDateInterval !== undefined) {
                    setDateInterval({
                      from: day,
                      to: undefined,
                    });
                  }
                } else {
                  if (setDateInterval !== undefined) {
                    setDateInterval({
                      from: currDateInterval?.from,
                      to: day,
                    });
                  }
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
