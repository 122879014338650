import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerWrapper } from "components/SpinnerWrapper/SpinnerWrapper";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { debounce } from "helpers/utils";
import { Recipe } from "types/nutrition";
import {
  searchRecipes,
  selectIsLoading,
  selectRecipes,
} from "slices/nutritionSchedulerSlice";

type AddIngredientModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  recipes: Recipe[];
  setRecipe: (recipe: Recipe) => void;
};

type RecipeWithSelection = Recipe & { isSelected: boolean };

export const SearchRecipe = ({
  showModal,
  setShowModal,
  recipes,
  setRecipe,
}: AddIngredientModalI) => {
  const dispatch = useDispatch();
  const recipesFromStore = useSelector(selectRecipes);
  const isLoading = useSelector(selectIsLoading);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedRecipes, setSearchedRecipes] = useState<RecipeWithSelection[]>(
    []
  );

  useEffect(() => {
    dispatch(searchRecipes(null));
  }, [dispatch]);

  useEffect(() => {
    // First, create a map of ingredients with isSelected set to true
    const initialIngredients =
      recipes?.reduce((acc, recipe) => {
        acc[recipe.id] = { ...recipe, isSelected: true };
        return acc;
      }, {} as { [key: string]: RecipeWithSelection }) ?? {};

    // Then, merge this map with the current searchedIngredients
    const mergedIngredients =
      recipesFromStore?.map((recipe) => {
        // If the ingredient exists in initialIngredients, use that, otherwise use the existing ingredient
        return (
          initialIngredients[recipe.id] || { ...recipe, isSelected: false }
        );
      }) || [];

    // Finally, set the searchedIngredients state with the merged ingredients
    setSearchedRecipes(mergedIngredients);
  }, [recipesFromStore, recipes]); // eslint-disable-line

  useEffect(() => {
    if (!showModal) {
      setSearchQuery("");
      setSearchedRecipes([]);
    }
  }, [showModal]); // eslint-disable-line

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchRecipes(query));
      }, 500),
    [dispatch]
  );

  const searchIngredient = (query: string) => {
    debouncedSearch(query);
  };

  return (
    <div className="add-ingredient-modal">
      <GInput
        autoFocus
        value={searchQuery}
        onChange={(e: any) => {
          setSearchQuery(e.target.value);
          searchIngredient(e.target.value);
        }}
        label="Pesquisar..."
      />
      {isLoading ? (
        <div className="loading-div">
          <SpinnerWrapper />
        </div>
      ) : (
        <div className="ingredient-list">
          {searchedRecipes.map((recipe: RecipeWithSelection) => {
            return (
              <GButton
                className="ingredient-button"
                key={recipe.id}
                onClick={() => setRecipe(recipe)}
                variant={recipe.isSelected ? "secondary" : "primary"}
                disabled={recipe.isSelected}
              >
                <div className="ingredient-info">
                  <p>{recipe.name}</p>
                </div>
              </GButton>
            );
          })}
        </div>
      )}
    </div>
  );
};
