import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GButton } from "components/genericComponents/GButton/GButton";
import { GInput } from "components/genericComponents/GInput/GInput";
import { GSection } from "components/genericComponents/GSection/GSection";
import {
  UpdateCompanyType,
  selectCurrentUser,
  setErrorMessage,
  updateCompany,
  selectConfirmationMessage,
  selectErrorMessage,
  setConfirmationMessage,
} from "slices/currentUserSlice";
import "components/SettingsPage/PaymentsSettings/ExternalAPIs/ExternalAPIs.scss";
import { toast } from "react-toastify";

export const ExternalAPIs = () => {
  const dispatch = useDispatch();
  const company = useSelector(selectCurrentUser)?.company;
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);
  const [formData, setFormData] = useState<UpdateCompanyType>({
    id: company?.id ?? "",
    viva_smart_checkout_secret_key: company?.viva_smart_checkout_secret_key,
    viva_smart_checkout_client_id: company?.viva_smart_checkout_client_id,
    vendus_api_key: company?.vendus_api_key,
  });

  useEffect(() => {
    if (company) {
      setFormData({
        ...formData,
        id: company.id,
        viva_smart_checkout_secret_key: company.viva_smart_checkout_secret_key,
        viva_smart_checkout_client_id: company.viva_smart_checkout_client_id,
        vendus_api_key: company.vendus_api_key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const submitCompanyInfo = () => dispatch(updateCompany(formData));

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "company-info-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "company-info-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));
    }
  }, [dispatch, confirmationMessage]);

  return (
    <div className="customer-payments-plans-and-insurance-div">
      <GSection label="Viva - Pagamentos">
        <div className="company-info-container">
          <div className="company-info-value-div">
            <div>
              <p className="company-info-label">Smark Checkout Client ID</p>
              <GInput
                className="company-info-input"
                value={formData.viva_smart_checkout_client_id}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    viva_smart_checkout_client_id: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <p className="company-info-label">Smark Checkout Secret Key</p>
              <GInput
                className="company-info-input"
                value={formData.viva_smart_checkout_secret_key}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    viva_smart_checkout_secret_key: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="submit-div">
            <GButton label="Submeter" onClick={submitCompanyInfo} />
          </div>
          <p className="company-info-create-account">
            {" "}
            <a
              className="company-info-create-account-link"
              href="https://goactive.vendus.pt/login/"
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui
            </a>{" "}
            para criar conta na Vendus e obter a API Key
          </p>
        </div>
      </GSection>
      <GSection label="Vendus - Faturação">
        <div className="company-info-container">
          <div className="company-info-value-div">
            <div>
              <p className="company-info-label">API Key</p>
              <GInput
                className="company-info-input"
                value={formData.vendus_api_key}
                onChange={(e: any) =>
                  setFormData({ ...formData, vendus_api_key: e.target.value })
                }
              />
            </div>
          </div>
          <div className="submit-div">
            <GButton label="Submeter" onClick={submitCompanyInfo} />
          </div>
          <p className="company-info-create-account">
            {" "}
            <a
              className="company-info-create-account-link"
              href="https://app.vivawallet.com/register/invite/2/77e05706-ac3c-42b0-acf5-69c4ada1ec74"
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui
            </a>{" "}
            para criar conta na Viva e obter as credenciais Smart Checkout
          </p>
        </div>
      </GSection>
    </div>
  );
};
