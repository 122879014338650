import { fork } from "redux-saga/effects";

import userSaga from "sagas/userSaga";
import currentUserSaga from "sagas/currentUserSaga";
import workoutSaga from "sagas/workoutSaga";
import exerciseSaga from "sagas/exerciseSaga";
import waitlistSaga from "sagas/waitlistSaga";
import courseSaga from "sagas/courseSaga";
import courseScheduleSaga from "sagas/courseScheduleSaga";
import workoutScheduleSaga from "sagas/workoutScheduleSaga";
import notificationsSaga from "sagas/notificationSaga";
import chatgptSaga from "sagas/chatgptSaga";
import chatSaga from "sagas/chatSaga";
import personalTrainerSaga from "sagas/personalTrainerSaga";
import exerciseCategorySaga from "sagas/exerciseCategorySaga";
import gymRoomSaga from "sagas/gymRoomSaga";
import paymentsPackagesSaga from "sagas/paymentsPackagesSaga";
import paymentsSaga from "sagas/paymentsSaga";
import workoutHistorySaga from "sagas/workoutHistorySaga";
import insuranceSaga from "sagas/insuranceSaga";
import productSaga from "sagas/productSaga";
import productTransactionSaga from "sagas/productTransactionSaga";
import crmSaga from "sagas/crmSaga";
import evaluationsSaga from "sagas/evaluationsSaga";
import nutritionSaga from "sagas/nutritionSaga";
import companyPlanSaga from "sagas/companyPlanSaga";
import dietSaga from "sagas/dietSaga";

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(currentUserSaga);
  yield fork(workoutSaga);
  yield fork(exerciseSaga);
  yield fork(waitlistSaga);
  yield fork(courseSaga);
  yield fork(courseScheduleSaga);
  yield fork(workoutScheduleSaga);
  yield fork(notificationsSaga);
  yield fork(chatgptSaga);
  yield fork(chatSaga);
  yield fork(personalTrainerSaga);
  yield fork(exerciseCategorySaga);
  yield fork(gymRoomSaga);
  yield fork(paymentsPackagesSaga);
  yield fork(paymentsSaga);
  yield fork(workoutHistorySaga);
  yield fork(insuranceSaga);
  yield fork(productSaga);
  yield fork(productTransactionSaga);
  yield fork(crmSaga);
  yield fork(evaluationsSaga);
  yield fork(nutritionSaga);
  yield fork(companyPlanSaga);
  yield fork(dietSaga);
}
