import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Product, ProductRevenueType } from "../types/product";
import {
  productSuccessfullyCreated,
  productSuccessfullyUpdated,
  productSuccessfullyDeleted,
} from "../helpers/messages";

type ProductState = {
  products: Product[];
  confirmationMessage: string | undefined;
  productRevenue: ProductRevenueType | undefined;
  errorMessage: string | undefined;
  isLoading: boolean;
};

export type FetchProductRevenueType = {
  start_at: string;
  end_at: string;
};

export type CreateOrUpdateProductType = {
  id?: string;
  title: string;
  purchase_price: number;
  sale_price: number;
  tax: number;
  tax_exemption: string;
  other_costs?: number;
};

const initialState: ProductState = {
  products: [],
  productRevenue: undefined,
  confirmationMessage: undefined,
  errorMessage: undefined,
  isLoading: false,
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    fetchProducts(state: ProductState) {
      state.isLoading = true;
    },
    fetchProductsSucceeded(
      state: ProductState,
      action: PayloadAction<Product[]>
    ) {
      state.products = action.payload;
      state.isLoading = false;
    },
    createProduct(
      state: ProductState,
      action: PayloadAction<CreateOrUpdateProductType>
    ) {},
    createProductSucceeded(
      state: ProductState,
      action: PayloadAction<Product>
    ) {
      return {
        ...state,
        confirmationMessage: productSuccessfullyCreated,
        products: [action.payload, ...state.products],
      };
    },
    updateProduct(
      state: ProductState,
      action: PayloadAction<CreateOrUpdateProductType>
    ) {},
    updateProductSucceeded(
      state: ProductState,
      action: PayloadAction<Product>
    ) {
      const index = state.products.findIndex(
        (product: Product) => product.id === action.payload.id
      );

      state.products[index] = action.payload;
      state.confirmationMessage = productSuccessfullyUpdated;
    },
    deleteProduct(state: ProductState, action: PayloadAction<string>) {},
    deleteProductSucceeded(state: ProductState, action: PayloadAction<string>) {
      const index = state.products.findIndex(
        (product: Product) => product.id === action.payload
      );

      state.products.splice(index, 1);
      state.confirmationMessage = productSuccessfullyDeleted;
    },
    fetchProductRevenue(
      state: ProductState,
      action: PayloadAction<FetchProductRevenueType>
    ) {},
    fetchProductRevenueSucceeded(
      state: ProductState,
      action: PayloadAction<ProductRevenueType>
    ) {
      state.productRevenue = action.payload;
    },
    setErrorMessage(
      state: ProductState,
      action: PayloadAction<string | undefined>
    ) {
      state.errorMessage = action.payload;
    },
    setConfirmationMessage(
      state: ProductState,
      action: PayloadAction<string | undefined>
    ) {
      state.confirmationMessage = action.payload;
    },
  },
});

export const {
  fetchProducts,
  fetchProductsSucceeded,
  setConfirmationMessage,
  setErrorMessage,
  createProduct,
  createProductSucceeded,
  updateProduct,
  updateProductSucceeded,
  deleteProduct,
  deleteProductSucceeded,
  fetchProductRevenue,
  fetchProductRevenueSucceeded,
} = productSlice.actions;

export const selectProducts = (state: RootState) => state.productSlice.products;
export const selectProductRevenue = (state: RootState) =>
  state.productSlice.productRevenue;
export const selectErrorMessage = (state: RootState) =>
  state.productSlice.errorMessage;
export const selectConfirmationMessage = (state: RootState) =>
  state.productSlice.confirmationMessage;
export const selectIsLoading = (state: RootState) =>
  state.productSlice.isLoading;

export default productSlice.reducer;
