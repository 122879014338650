import { IngredientModalLogic } from "components/NutritionPage/AddIngredientModal/IngredientModalLogic/IngredientModalLogic";
import { GModal } from "components/genericComponents/GModal/GModal";
import { Food } from "types/nutrition";

type AddIngredientModalI = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  ingredients: Food[];
  setIngredient: (ingredient: Food) => void;
};

export const AddIngredientModal = ({
  showModal,
  setShowModal,
  ingredients,
  setIngredient,
}: AddIngredientModalI) => {
  return (
    <GModal showModalState={showModal} setShowModalState={setShowModal}>
      <div className="add-ingredient-modal">
        <h4>Adicionar Ingrediente</h4>
        <IngredientModalLogic
          showModal={showModal}
          setShowModal={setShowModal}
          ingredients={ingredients}
          setIngredient={setIngredient}
        />
      </div>
    </GModal>
  );
};
