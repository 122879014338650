import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { DashboardTitle } from "components/DashboardTitle/DashboardTitle";
import { Tabs, TabsI } from "components/Tabs/Tabs";
import { GSection } from "components/genericComponents/GSection/GSection";

export const NutritionPage = () => {
  const navigate = useNavigate();
  const tabs = ["Dietas"];
  const [tabsData, setTabsData] = useState<TabsI>({
    tabs: tabs,
    activeIndex: 0,
  });
  const isNutritionHomePage = window.location.pathname === "/dashboard/nutrition/diets";

  useEffect(() => {
    const pathname = window.location.pathname;
    const nutritionPath = "/dashboard/nutrition";
    if (pathname === nutritionPath || pathname === nutritionPath + "/") {
      navigate("/dashboard/nutrition/diets");
    }
  }, [navigate]);

  const onTabClick = (activeIdx: number) => {
    setTabsData({
      ...tabsData,
      activeIndex: activeIdx,
    });
  };

  const addDiet = () => {
    navigate("/dashboard/nutrition/diets/add-diet");
  };

  return (
    <div id="tablet-page">
      <DashboardTitle
        title="Nutrição"
        doNotShowBackButton={isNutritionHomePage}
        onClickButton={addDiet}
        showAddButton={isNutritionHomePage}
      />
      {isNutritionHomePage && (
        <Tabs
          tabs={tabsData.tabs}
          activeIndex={tabsData.activeIndex}
          onTabClick={onTabClick}
        />
      )}
      <GSection>
        <Outlet />
      </GSection>
    </div>
  );
};
